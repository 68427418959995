import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'gender'  
})
export class GenderPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private genders: Gender[] = [{}];


  transform(gender_id: number | undefined): string {

    if(!gender_id){
      return "";
    }
      /* Check if the gender array has empty object */
    if (this.genders.length == 1) {
      this.masterDataService.gender$.subscribe(genders => {
        this.genders = genders;
      })
    }
    const gender = this.genders.filter(gender => gender.gender_id == gender_id )
    return gender[0].gender || '';
  }

}
