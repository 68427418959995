import { Pipe, PipeTransform } from '@angular/core';
import { MotherTongue } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'motherTongue'
})
export class MotherTonguePipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private motherTongues: MotherTongue[] = [{}];


  transform(motherTongueIds: number[] | number | undefined): string {

    /* Check if the motherTongue array has empty object */
    if (this.motherTongues.length == 1) {
      this.masterDataService.motherTongue$.subscribe(motherTongues => {
        this.motherTongues = motherTongues;
      })
    }
    if (!motherTongueIds) {
      // Undefined
      return "";
    }
    else if (typeof (motherTongueIds) === 'object') {
      let motherTongueString: string = '';
      // Array 
      motherTongueIds.forEach((motherTongue_id) => {
        const motherTongue = this.motherTongues.filter(motherTongue => motherTongue.mother_tongue_id == motherTongue_id);
        if (motherTongueString === '') {
          // Append without comma
          motherTongueString += motherTongue[0].mother_tongue || ''
        } else {
          // Append with comma
          motherTongueString += ', ' + motherTongue[0].mother_tongue || ''
        }


      });
      return motherTongueString;



    } else {
      // number

      const motherTongue = this.motherTongues.filter(motherTongue => motherTongue.mother_tongue_id === motherTongueIds)
      return motherTongue[0].mother_tongue || '';
    }


  }

}
