import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
import { Habit } from '../master-data';

@Pipe({
  name: 'habit'  
})
export class HabitPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private habits: Habit[] = [{}];


  transform(habit_id: number | undefined, habit_type_id: number ): string {

    if(!habit_id){
      return "";
    }
      /* Check if the habit array has empty object */
    if (this.habits.length == 1) {
      this.masterDataService.habit$.subscribe(habits => {
        this.habits = habits;
      })
    }
    const habit = this.habits.filter(habit => habit.habit_id === habit_id && habit.habit_type_id === habit_type_id)
    return habit[0].habit || '';
  }

}
