import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  transform(value: string): string {
    /* Check if string has atleast one char */
    if(value.length){
      return value.substring(0,1);
    }
    return '';
  }

}
