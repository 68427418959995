import { trigger, animate, style, group, query, transition, animation, animateChild, useAnimation } from '@angular/animations';

const slideInAnimation = animation([
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),
    query(':leave', style({ zIndex: 100 }), { optional: true }),
    query(':enter', style({ transform: 'translateX(100%)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)', opacity: 0 })), // x: '-100%'
            animateChild()
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0%)' })),
            animateChild()
        ]), { optional: true })
    ])
]);

const slideOutAnimation = animation([
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),
    query(':leave', style({ zIndex: 100 }), { optional: true }),
    query(':enter', style({ transform: 'translateX(-100%)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)', opacity: 0 })), // x: '100%'
            animateChild()
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0%)' })),
            animateChild()
        ]), { optional: true })
    ])
]);

const slideUpAnimation = animation([
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),
    query(':leave', style({ zIndex: 100 }), { optional: true }),
    query(':enter', style({ transform: 'translateY(100%)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(-100%)', opacity: 0 })), // y: '-100%'
            animateChild()
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(0%)' })),
            animateChild()
        ]), { optional: true })
    ])
]);

const slideDownAnimation = animation([
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),
    query(':leave', style({ zIndex: 100 }), { optional: true }),
    query(':enter', style({ transform: 'translateY(-100%)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(100%)', opacity: 0 })), // y: '100%'
            animateChild()
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translateY(0%)' })),
            animateChild()
        ]), { optional: true })
    ])
]);

const crossfadeAnimation = animation([
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),
    group([
        query(':enter', [
            style({ opacity: 0 }),
            animate('300ms linear', style({ opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
            style({ opacity: 1 }),
            animate('300ms linear', style({ opacity: 0 }))
        ], { optional: true }),
    ])
]);

export const routerTransition = trigger('routerAnimations', [
    // transition('home => level1', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('home => level2', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('home => level3', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('home => level4', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('home => base', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('base => level1', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level1 => level2', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level1 => level3', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level1 => level4', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level2 => level3', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level2 => level4', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level3 => level4', [
    //     useAnimation(slideInAnimation)
    // ]),
    // transition('level4 => level3', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level4 => level2', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level4 => level1', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level3 => level2', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level3 => level1', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level2 => level1', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('level1 => base', [
    //     useAnimation(slideOutAnimation)
    // ]),
    // transition('* => slideUp', [
    //     useAnimation(slideUpAnimation)
    // ]),
    // transition('slideUp => *', [
    //     useAnimation(slideDownAnimation)
    // ]),
    transition('* => *', [
        useAnimation(slideOutAnimation)
    ]),

]);
