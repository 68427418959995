import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MasterDataService } from './master-data/master-data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vaniya Chettiyar Staging';

  constructor(
    private readonly translate: TranslateService,
    private masterDataService: MasterDataService,
  ) {
      this.translate.setDefaultLang('en-GB');
      this.translate.use('en-GB');
      this.masterDataService.loadMasterData();

  }

}
