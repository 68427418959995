<mat-toolbar class="main-header">
    <div class="branding">
        <div class="logo"></div>
    </div>
    <div>
        <span>{{ data.title }}</span>
    </div>
    <div fxFlex></div>
</mat-toolbar>
<mat-dialog-content>
  <div *ngIf="!data.isHtml">{{ data.message }}</div>
  <div *ngIf="data.isHtml" class="" [innerHtml]="data.message | safeHtml"> </div>
 </mat-dialog-content>
<mat-dialog-actions>
    <span fxFlex></span>
    <button mat-raised-button color="primary" (click)="confirm()" mat-dialog-close>Ok</button>
</mat-dialog-actions>
