import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private countries: ICountry[] = [];


  transform(country_id: number | undefined): string {

    if (!country_id) {
      return "";
    }
    /* Check if the country array has empty object */
    if (this.countries.length == 0) {
      this.masterDataService.country$.subscribe(countries => {
        this.countries = countries;
      })
    }
    const country = this.countries.filter(country => country.country_id == country_id)
    return country[0].country_name || '';
  }

}
