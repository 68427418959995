import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { UserService } from '../../shared/services/user.service';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-form-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    
    formLoginGroup!: FormGroup;
    public showSpinner: boolean = false;
    constructor(
        private readonly fb: FormBuilder,
        public authService: AuthService,
        public userService: UserService,
        // public dialog: MatDialog,
        private readonly translate: TranslateService,
        private router: Router
    ) {
        
    }

    ngOnInit() {
        // Get user details give by firebase auth. This will not contain user profile details.
        //this.userDetails = this.authService.getUserDetails();
        this.setFormGroup();
    }

    setFormGroup() {

        this.formLoginGroup = this.fb.group({

            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],

        });
    }

    saveLogin() {
        this.showSpinner = true;
        if (this.authService.isDebugMode()) {
            this.authService.displayConsoleMessage(this.formLoginGroup.value);
        }
        const userLoginDetailsJson = {
            
            email: this.formLoginGroup.get('email')!.value,
            password: this.formLoginGroup.get('password')!.value,
            
        };

        // Check if one of the fields in the form group has been modified
        if (this.formLoginGroup.dirty && this.formLoginGroup.valid) {

            // Insert User DETAILS
            this.userService.loginUser(userLoginDetailsJson).subscribe(response => {
                
                // Remove all the other user details set by previous login if any
                this.authService.clearUserDetails();
                
                // Save authorize token in local storage                
                this.authService.setAuthToken(response.data.token);
                this.authService.setUserDetails(response.data);
                //this.authService.setUserLoginStatus(true);
                //this.router.navigate(['user/home']);
                this.showSpinner = false;
            });

            
        }
    }

}
