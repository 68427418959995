import { Component } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
// import { PermissionsService } from '../../services/permissions.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'nav-list',
    templateUrl: './nav.component.html'
})
export class NavComponent {

    teams: Array<any> = [];
    languages: Array <any> = [{key: 'en', value: 'English'}, {key: 'fr', value: 'French'}];
    constructor(
        // private translate: TranslateService,
        private ngxPermissions: NgxPermissionsService,

        // private permissionsService: PermissionsService
        ) {

    }


    // ngAfterViewInit() {
    //     setTimeout(() => {
    //         // this.permissionsService.setPermissions(['common/worklist']);
    //     });
    // }

   public  setDefaultLang = (langId: number) => {
    // this.translate.use(langId);
   }

}

