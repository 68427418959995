import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IScreensize } from 'src/app/app';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';
import { ScrollHandleService } from 'src/app/shared/services/scroll-handle.service';
import { UserService } from 'src/app/shared/services/user.service';
import { IPaginationMetaData, IResultsUserDetails } from 'src/app/user-details-form/user-details';

@Component({
  selector: 'app-get-profiles',
  templateUrl: './get-profiles.component.html',
  styleUrls: ['./get-profiles.component.scss']
})
export class GetProfilesComponent implements OnInit {

  public users: IResultsUserDetails[] = []
  public title: string = '';
  public screenInfo!: IScreensize;
  public showSpinner: boolean = false;
  private metaData!: IPaginationMetaData;
  public endSearchResult = false;
  private userSearchDetailsJson: any;
  // This is used to show no image for all the users when they view profiles as guest 
  public genderId!:number;
  constructor(
    private router: Router,
    private userService: UserService,
    private scrollHandleService: ScrollHandleService,
    private screensizeDetectService: ScreensizeDetectService
  ) {

    this.showSpinner = true;
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    })

    this.scrollHandleService.triggerSearch$.subscribe(triggerSearch => {
      if (triggerSearch) {

          if (this.metaData.current_page < this.metaData.last_page) {
              this.doSearch(this.metaData.current_page + 1);
          } else {
              this.endSearchResult = true;
          }
      }
  })

    const urlArray = this.router.url.split('/');
    //const subUrl = this.router.url.indexOf('/', 3);
    const subUrl = urlArray['3'] + '/' + urlArray['4']
    switch (subUrl) {

      case 'shortlisted/mine':
        this.title = 'shortlisted Profiles';
        this.userService.getUserFavourites(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'shortlisted/others':
        this.title = 'Who Likes My Profile';
        this.userService.getUsersWhoLikeMe(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });

        break;

      case 'interested/mine':
        this.title = 'Interests Sent';
        this.userService.getUserInterestsSent(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'interested/others':
        this.title = 'Interests Received';
        this.userService.getUsersInterestsReceived(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });

        break;


      case 'viewed/mine':

        this.title = 'My View History';
        this.userService.getUserViewHistory(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'viewed/others':

        this.title = 'Who Viewed My Profile';
        this.userService.getUsersWhoViewedMe(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'contacted/mine':
        this.title = 'My Contact View History';
        this.userService.getUserContactViewHistory(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'contacted/others':
        this.title = 'Who Viewed My Contact Details';
        this.userService.getUsersWhoViewedMyContact(null).subscribe(users => {
          this.users = users.data;
          this.showSpinner = false;
        });
        break;

      case 'ignored/mine':
        // this.title = 'Who Likes Me';
        // this.userService.getUsersWhoLikeMe(null).subscribe(users => {
        //   this.users = users.data;
        // });
        break;


      case 'blocked/mine':
        // this.title = 'Who Likes Me';
        // this.userService.getUsersWhoLikeMe(null).subscribe(users => {
        //   this.users = users.data;
        // });
        break;

      case 'vaniyachettiyar/male':
        this.title = 'Vaniya Chettiyar Male Profiles';
        this.genderId = 2;
        this.userSearchDetailsJson = {
          caste_id: [197],
          /**
           * Invert the gender id. Because gender_id here is to represent the current user id so that opposite gender profiles will be listed.
           * 
           * So set the current user as female to get male profiles
           */
          

          
          gender_id: 2 
        }

        this.doSearch(1)


 
        break;

      case 'vaniyachettiyar/female':
        this.title = 'Vaniya Chettiyar Female Profiles';
        this.genderId = 1;
        this.userSearchDetailsJson = {
          caste_id: [197],
          gender_id: 1
        }

        this.doSearch(1)
        break;

      default:

        break;
    }

  }

  ngOnInit(): void {
  }

  doSearch(nextPage: number) {
    
    // Insert User DETAILS
    this.userService.getCommunityProfiles(this.userSearchDetailsJson, nextPage).subscribe(users => {
        
        /* Append the new results with the existings.  */
        /* Todo: Need to set max limit that should be displayed at any point in time to handle browser memory issue */
        this.users = this.users.concat(users.data);
        //this.users = [...this.users, users.data];
        this.metaData = users.meta;
        if (users.data.length) {
            //this.showMobileForm = false;
            /* Inform layout compoent that we have found a new result set. Keep an eye on the scrolling end position */
            this.scrollHandleService.captureScrollPositon();
            this.showSpinner = false;
            //this.userService.getScrollingElement();
        }

    });
}

}
