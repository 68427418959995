import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutNewUserComponent } from './core/layout-new-user/layout-new-user.component';
import { LayoutComponent } from './core/layout/layout.component';
//import { IndexComponent } from './components/index/index.component';
// import { SignInComponent } from './feature/sign-in/sign-in.component';
// import { SignOutComponent } from './feature/sign-out/sign-out.component';
// import { SignUpComponent } from './feature/sign-up/sign-up.component';
// import { ForgotPasswordComponent } from './feature/forgot-password/forgot-password.component';
// import { VerifyEmailComponent } from './feature/verify-email/verify-email.component';

// import { LogoutComponent } from './feature/logout/logout.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginOtpComponent } from './components/login-otp/login-otp.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { GetProfilesComponent } from './components/search/get-profiles/get-profiles.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { PrimaryDetailsComponent } from './user-details-form/primary-details/primary-details.component';

//import { HomeComponent } from './components/home/home.component';


//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/new-user/login']);
//const redirectLoggedInToHome = () => redirectLoggedInTo(['/user/home']);

// Refer: https://github.com/angular/angularfire/blob/master/docs/auth/router-guards.md

const routes: Routes =
  [

    { path: '', pathMatch: 'full', redirectTo: 'landing', data: {} },
    { path: 'landing', component: LandingComponent },

    /*
    {
       path: 'user', component: LayoutComponent, 
       children:[
         { path: 'deta', loadChildren: () => import('./components/user-details/user-details.module').then(m => m.UserDetailsModule) },
       ]

    },
    */

    // {path: 'register', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },

    {

      path: 'new-user', component: LayoutNewUserComponent,
      children: [

        { path: 'signup', component: PrimaryDetailsComponent },
        { path: 'login', component: LoginComponent },
        { path: 'login/otp', component: LoginOtpComponent },
        /* :userId is for the new users who were added by the admin  */
        { path: 'login/otp/:userId', component: LoginOtpComponent },
        { path: 'verify/:userId', component: LoginOtpComponent },
        
        /* This will take user to verification form as the otp would be sent from another page */
        { path: 'login/otp/verify/:verificationId', component: LoginOtpComponent },

        { path: 'logout', component: LogoutComponent },
        { path: 'privacy-policy', component: PrivacyPolicyComponent },
        { path: 'terms-conditions', component: TermsConditionsComponent },
        { path: 'about-us', component: AboutUsComponent },
        { path: 'faqs', component: FaqsComponent },
        { path: 'contact-us', component: ContactUsComponent },
        


      ]
    },
    { path: 'error-page', pathMatch: 'full', component: ErrorPageComponent },

    // { path: 'signin', component: SignInComponent,  outlet: 'login',
    //  canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome }},

    // { path: 'signup', component: SignUpComponent,  outlet: 'login'},


    {
      path: 'user', component: LayoutComponent, data: {},
      children: [
        //{ path: '', pathMatch: 'full', redirectTo: 'netapp' },


        // This will redirect to home page if authenticationGuard fail. Pls refer the above ref link.
        { path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },
        { path: 'details', loadChildren: () => import('./components/user-details/user-details.module').then(m => m.UserDetailsModule) },
        //{ path: 'search', loadChildren: () => import('./components/search/search.module').then(m => m.SearchModule), canActivate: [AuthGuard] },
        { path: 'search', loadChildren: () => import('./components/search/search.module').then(m => m.SearchModule) },
        { path: 'search/vaniyachettiyar/male', component: GetProfilesComponent },
        { path: 'search/vaniyachettiyar/female', component: GetProfilesComponent },
        
        //{ path: 'get-details', loadChildren: () => import('./feature/get-details/get-details.module').then(m => m.GetDetailsModule) },
        //{ path: '**', pathMatch: 'full', component: PageNotFoundComponent }
      ]
    },
    {
      path: 'mmo', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    },


    { path: '**', pathMatch: 'full', component: ErrorPageComponent }

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
