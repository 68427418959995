
<div *ngIf="datatype === 'array'">
    Please correct the following errors
    <ul>
        <li *ngFor="let message of data"> {{ message }}</li>
    </ul>
</div>

<div *ngIf="datatype === 'string'" fxLayout="row" fxLayoutAlign="center center" >
    {{ data }}
</div>
