import { Pipe, PipeTransform } from '@angular/core';
import { Religion } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'religion'
})
export class ReligionPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private religions: Religion[] = [{}];


  transform(religionIds: number[] | number | undefined): string {
    /* Check if the religion array has empty object */
    if (this.religions.length == 1) {
      this.masterDataService.religion$.subscribe(religions => {
        this.religions = religions;
      })
    }
    if (!religionIds) {
      // Undefined
      return "";
    }
    else if (typeof (religionIds) === 'object') {
      let religionString: string = '';
      // Array 
      religionIds.forEach((motherTongueId) => {
        const religion = this.religions.filter(religion => religion.religion_id === motherTongueId);
        if (religionString === '') {
          // Append without comma
          religionString += religion[0].religion || ''
        } else {
          // Append with comma
          religionString += ', ' + religion[0].religion || ''
        }


      });
      return religionString;

    } else {
      // number

      const religion = this.religions.filter(religion => religion.religion_id == religionIds)
    return religion[0].religion || '';
    }

    
  }

}
