import { Pipe, PipeTransform } from '@angular/core';
import { Caste } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'caste'
})
export class CastePipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private castes: Caste[] = [{}];


  transform(casteIds: number[] | number | undefined): string {

    /* Check if the caste array has empty object */
    if (this.castes.length == 1) {
      this.masterDataService.caste$.subscribe(castes => {
        this.castes = castes;
      })
    }

    if (!casteIds) {
      // Undefined
      return "";
    }
    else if (typeof (casteIds) === 'object') {
      let casteString: string = '';
      // Array 
      casteIds.forEach((caste_id) => {
        const caste = this.castes.filter(caste => caste.caste_id === caste_id);
        if (casteString === '') {
          // Append without comma
          casteString += caste[0].caste || ''
        } else {
          // Append with comma
          casteString += ', ' + caste[0].caste || ''
        }


      });
      return casteString;



    } else {
      // number

      const caste = this.castes.filter(caste => caste.caste_id == casteIds)
      return caste[0].caste || '';
    }


  }

}
