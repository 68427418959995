import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  displayLogoutSuccessMsg: boolean = false;
  displayLogoutAllSuccessMsg: boolean = false;
  constructor(public userService: UserService, 
    public authService: AuthService) { }

  ngOnInit(): void {
    this.logout();
  }

  logout(){
    this.userService.logoutUser().subscribe(Response => {
      this.authService.clearUserDetails();
      this.displayLogoutSuccessMsg = true;
    })
  }

  logoutFromAllDevices(){
    this.userService.logoutUserFromAllDevices().subscribe(Response => {
      this.displayLogoutAllSuccessMsg = true;
    })
  }

}
