import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
import { ResidentStatus } from '../master-data';

@Pipe({
  name: 'residentStatus'  
})
export class ResidentStatusPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private residentStatuses: ResidentStatus[] = [{}];

  transform(residentStatusId: number | undefined): string {

    if(!residentStatusId){
      return "";
    }
      /* Check if the residentStatus array has empty object */
    if (this.residentStatuses.length == 1) {
      this.masterDataService.residentStatus$.subscribe(residentStatuses => {
        this.residentStatuses = residentStatuses;
      })
    }
    const residentStatus = this.residentStatuses.filter(residentStatus => residentStatus.resident_status_id == residentStatusId )
    return residentStatus[0].resident_status || '';
  }

}
