import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollHandleService {

  /*  This it intimate from layout componet to search that scrolling has reached the bottom. Do get the next set of results */
  private triggerSeachSource = new BehaviorSubject<boolean>(false);
  triggerSearch$ = this.triggerSeachSource.asObservable();

/*  This it intimate from search componet to layout that we have found a new set of results. So capture the scrolling position end */
  private searchResultSource = new BehaviorSubject<boolean>(false);
  searchResult$ = this.searchResultSource.asObservable();

  constructor() { }

  triggerSearch() {
    this.triggerSeachSource.next(true);
  }

  captureScrollPositon() {
    this.searchResultSource.next(true);
  }
}
