
<mat-nav-list appAccordion class="navigation" style="padding-top: 1px;">
    <mat-list-item appAccordionLink>
        <a appAccordionToggle class="relative" [routerLink]="['/user/home']" routerLinkActive="active-link">
            <mat-icon>home</mat-icon>
            <span>{{ 'HOME' | translate }}</span>
            <span fxFlex></span>
        </a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item appAccordionLink>
        <a appAccordionToggle class="relative" [routerLink]="['/user/search']" routerLinkActive="active-link">
            <mat-icon>view_list</mat-icon>
            <span>{{ 'Search' | translate }}</span>
            <span fxFlex></span>
        </a>
    </mat-list-item>
    <mat-divider></mat-divider>
</mat-nav-list>
