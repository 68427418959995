<div class="mo-sr-card-container">
    <ng-container *ngIf="users.length">
    <div *ngFor="let user of users; index as i; " class="mo-card-container">
        <mat-card class="mo-sr-card">
            <mat-card-header>
                <!-- <div mat-card-avatar class="example-header-image"></div> -->
                <!-- <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> -->
                <mat-card-title>{{user.first_name}} </mat-card-title>
                <mat-card-subtitle *ngIf="user.occupation_id">{{ user.occupation_id | occupation }}</mat-card-subtitle>
                <mat-card-subtitle *ngIf="!user.occupation_id">{{ user.occupation_details }}&nbsp;</mat-card-subtitle>

            </mat-card-header>
            <a [target]="screenInfo.screenSize !== 'xs' ? '_blank' : '_self'" [routerLink]="['/user/search/view', user.user_id]    ">
                <img *ngIf="user.primary_photo_path" mat-card-image src="{{thumbnailUrl + user.primary_photo_path }}"
                    alt="{{user.first_name}}">

                <img *ngIf="!user.primary_photo_path && genderId === 1" mat-card-image
                    src="{{noPhotoUrl + 'no-image-female' + '.jpg'}}" alt="No Photo Added By the User">

                <img *ngIf="!user.primary_photo_path  && genderId === 2" mat-card-image
                    src="{{noPhotoUrl + 'no-image-male' + '.jpg'}}" alt="No Photo Added By the User">
                    <!-- <mat-icon (click)="setUserFavouriteProfile(user.user_id)">favorite</mat-icon> -->
            </a>
            <mat-card-content>
                <span>{{user.age}}Y,</span>
                <span *ngIf="user.qualification_id">{{user.qualification_id | qualification}},</span>
                <span *ngIf="user.star_id">{{user.star_id | star }},</span>
                <span *ngIf="user.caste_id">{{ user.caste_id | caste }}</span>

            </mat-card-content>
            <!-- <mat-card-actions> -->
                <!-- <a target="_blank" [routerLink]="['/user/search/view', user.user_id]">VIEW</a> -->
                <!-- <button mat-button (click)="setUserFavouriteProfile(user.user_id)">SHORTLIST</button> -->
                <!-- <button mat-button>IGNORE</button> -->
            <!-- </mat-card-actions> -->
        </mat-card>

    </div>
</ng-container>

</div>