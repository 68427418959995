import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  templateUrl: './notification.component.html',
 // template: 'passed in {{ data }}',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public datatype:'array'|'string' = 'string';
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { 
    if(typeof(data) === 'object'){
      this.datatype = 'array';
    }else{
      this.datatype = 'string';
    }

  }

  ngOnInit() {
  }

}

