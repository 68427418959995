import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { RouterModule } from '@angular/router';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';

//import 'hammerjs';
import { SharedModule } from '../shared/shared.module';
import { LayoutNewUserComponent } from './layout-new-user/layout-new-user.component';
import { LayoutComponent } from './layout/layout.component';
import { NavComponent } from './nav/nav.component';
//import { IndexComponent } from '../feature/index/index.component';
import { AuthInterceptor } from '../core/interceptors/auth.interceptors';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { HttpErrorInterceptor } from './interceptors/error.http-interceptors';
import { HeaderInterceptor } from './interceptors/header.interceptors';
// import { ServiceWorkerUpdateService } from './service-worker-update.service';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,


        RouterModule,
        // ServiceWorkerModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatSnackBarModule,
        SharedModule,
        TranslateModule
    ],
    exports: [

    ],
    declarations: [
        NavComponent,
        LayoutComponent,
        LayoutNewUserComponent,
        ToggleFullscreenDirective,
        //IndexComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true

        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true,
        }
        
        // ServiceWorkerUpdateService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}

