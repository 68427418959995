import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IScreensize } from 'src/app/app';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';
import { IResultsUserDetails } from 'src/app/user-details-form/user-details';
import { environment } from 'src/environments/environment';
import { UserService } from '../../../shared/services/user.service';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  public thumbnailUrl = environment.imageUrl + 'thumbnail/';
  public noPhotoUrl = environment.imageUrl + 'watermark/';
  //public genderId:number = 1;
  public screenInfo!: IScreensize;
  @Input() users: IResultsUserDetails[] = [];
  @Input() genderId?: number;

  constructor(private userService: UserService,
    private notificationService: NotificationService,
    private readonly translate: TranslateService,
    private screensizeDetectService: ScreensizeDetectService
  ) {

    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
    if (!this.genderId) {

      this.genderId = +this.userService.getLocalStorageValue('genderId')!;

    }

  }

  ngOnInit(): void {
  }

  setUserFavouriteProfile(favouriteProfileId: string) {
    const params = { favourite_profile_id: favouriteProfileId };
    this.userService.markAsFavouriteProfile(params).subscribe(response => {
      let successMsg = '';
      this.translate.get('FAV_PROFILE_SAVE_SUCCESS_MSG').subscribe((tranlatedText: string) => {
        successMsg = tranlatedText;
      });
      this.notificationService.displayNotification(successMsg)
    });
  }







}
