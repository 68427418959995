<div class="mo-about-container">
    <h2> Terms and conditions</h2>
    <div class="mo-about-section">
        Welcome to Vaniya Chettiyar, India&#39;s premier online matrimonial website. Please read these terms and
        conditions carefully before using our platform.
    </div>
    <div class="mo-about-section">
        1. Definition of terms: Explain the meaning of key terms used in the T&amp;C, such as &quot;User&quot;,
        &quot;Service&quot;, &quot;Website&quot;, etc.
    </div>
    <div class="mo-about-section">
        2. Use of Service: Outline the conditions under which users are permitted to use the website
        and its services.
    </div>
    <div class="mo-about-section">
        3. User Accounts: Explain the process for creating a user account and the responsibilities of
        users in maintaining their account information.
    </div>
    <div class="mo-about-section">
        4. User Content: Detail the rules for submitting and posting content on the website, including
        restrictions on offensive or inappropriate material.
    </div>
    <div class="mo-about-section">
        5. Intellectual Property: Discuss the ownership of content submitted by users and the website,
        as well as the rights and responsibilities of each party.
    </div>
    <div class="mo-about-section">
        6. Limitation of Liability: State the extent to which the website and its owners are responsible
        for damages arising from the use of the site and its services.
    </div>
    <div class="mo-about-section">
        7. Indemnification: Outline the responsibility of users to indemnify the website and its owners
        in the event of any legal action or claim.
    </div>
    <div class="mo-about-section">
        8. Disclaimers: Provide a disclaimer of warranty for the website and its services, stating that
        they are provided &quot;as is&quot; without any guarantees.
    </div>
    <div class="mo-about-section">
        9. Modifications to Service: Explain the right of the website to modify its services at any time,
        and the obligation of users to regularly check the T&amp;C for updates.
    </div>
    <div class="mo-about-section">
        10. Termination of Service: Outline the circumstances under which the website may terminate a
        user&#39;s account and the rights and responsibilities of both parties in such cases.
    </div>
    <div class="mo-about-section">
        11. Governing Law: Specify the governing law that will be used in the event of any legal dispute
        arising from the use of the website and its services.
    </div>
    <div class="mo-about-section">
        12. Dispute Resolution: Explain the process for resolving disputes between users and the
        website, including the use of arbitration or mediation if necessary.
    </div>
    <div class="mo-about-section">
        13. Notices: Specify the method by which the website will communicate with users, such as
        email or posting on the website.
    </div>
    <div class="mo-about-section">
        14. Entire Agreement: State that these T&amp;C constitute the entire agreement between the user
        and the website, and supersede all prior agreements and understandings.
    </div>
    <div class="mo-about-section">
        15. Changes to T&amp;C: Explain the right of the website to make changes to the T&amp;C at any time,
        and the obligation of users to regularly check for updates.
    </div>
</div>