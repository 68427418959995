import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService, NotificationType } from 'src/app/shared/services/notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private notificationService: NotificationService,
    private readonly translate: TranslateService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // NOT SURE WHY THIS CODE IS REQUIRED AS WE JUST NEED TO CAPTURE THE ERROR CODE AND SEND MESSAGE ACCORDINGLY
          // let errMsg = '';
          // // Blob Errors
          // // https://github.com/angular/angular/issues/19888
          // if (error.error instanceof Blob) {
          //   const reader = new FileReader();
          //   reader.onload = e => {
          //     let errorMsg = JSON.parse((<any>e.target).result);
          //     if (errorMsg.ErrorInfo.Message) {
          //       errorMsg = errorMsg.ErrorInfo.Message;
          //     }
          //     this.ActBasedOnError(error, errorMsg);
          //   };
          //   reader.readAsText(error.error);
          // } else if (error.error instanceof ErrorEvent) {
          //   // Client Side Error
          //   errMsg = `Error: ${error.error.message}`;
          // } else {  // Server Side Error
          //   if (error.error) {
          //     errMsg = error.error.ErrorInfo === undefined ? error.error : error.error.ErrorInfo.Message;
          //   }
          //   this.ActBasedOnError(error, errMsg);
          // }

          this.ActBasedOnError(error);
          return throwError(() => error);
        })
      );
  }

  private ActBasedOnError(error: HttpErrorResponse): void {
    let errMsg = '';

    switch (error.status) {
      case 500: {

        this.translate.get('HTTP_500_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(errMsg, NotificationType.Error);
        break;
      }
      case 400: {
        this.translate.get('HTTP_400_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(errMsg, NotificationType.Warn);
        break;
      }
      case 401: {
        this.translate.get('HTTP_401_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(errMsg, NotificationType.Error);
        this.router.navigate(['/new-user/login']);
        break;
      }
      case 403: {
        this.translate.get('HTTP_403_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.router.navigate(['/error-page']);
        break;
      }
      case 413: {
        this.translate.get('HTTP_413_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(errMsg, NotificationType.Error);
        break;
      }
      case 422: {
        //console.log(error.error.errors);
        this.translate.get('HTTP_422_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(error.error.errors, NotificationType.Error);
        break;
      }
      // case 429: {
      //   //console.log(error.error.errors);
      //   this.translate.get('HTTP_429_ERROR_MSG').subscribe((tranlatedText: string) => {
      //     errMsg = tranlatedText;
      //   });

      //   // setTimeout(() => {
      //   //   console.log('reloading');
      //   //   console.log(this.router.url);

      //   //   this.router.navigate([this.router.url]);
      //   // }, 3000);

      //   this.notificationService.displayNotification(error.error.errors, NotificationType.Info);
      //   break;
      // }
      default: {
        this.translate.get('HTTP_DEFAULT_ERROR_MSG').subscribe((tranlatedText: string) => {
          errMsg = tranlatedText;
        });

        this.notificationService.displayNotification(errMsg, NotificationType.Error);
        break;
      }
    }
  }
}
