import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DisplayGrahamDirective } from './directives/display-graham.directive';
import { MasterDataService } from './master-data.service';
import { BodyTypePipe } from './pipes/body-type.pipe';
import { CastePipe } from './pipes/caste.pipe';
import { CityPipe } from './pipes/city.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { FamilyStatusPipe } from './pipes/family-status.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { GrahamPipe } from './pipes/graham.pipe';
import { HabitPipe } from './pipes/habit.pipe';
import { MaritalStatusPipe } from './pipes/marital-status.pipe';
import { MotherTonguePipe } from './pipes/mother-tongue.pipe';
import { OccupationPipe } from './pipes/occupation.pipe';
import { PhysicalStatusPipe } from './pipes/physical-status.pipe';
import { QualificationPipe } from './pipes/qualification.pipe';
import { RaasiPipe } from './pipes/raasi.pipe';
import { RelationPipe } from './pipes/relation.pipe';
import { ReligionPipe } from './pipes/religion.pipe';
import { ResidentStatusPipe } from './pipes/resident-status.pipe';
import { StarPipe } from './pipes/star.pipe';
import { StatePipe } from './pipes/state.pipe';


@NgModule({
  declarations: [
    BodyTypePipe,
    CastePipe,
    GenderPipe,
    HabitPipe,
    MaritalStatusPipe,
    MotherTonguePipe,
    OccupationPipe,
    PhysicalStatusPipe,
    FamilyStatusPipe,
    QualificationPipe,
    RaasiPipe,
    GrahamPipe,
    RelationPipe,
    ReligionPipe,
    ResidentStatusPipe,
    StarPipe,
    CountryPipe,
    StatePipe,
    CityPipe,
    DisplayGrahamDirective

  ],
  imports: [
    CommonModule
  ],
  exports:[
    BodyTypePipe,
    CastePipe,
    GenderPipe,
    HabitPipe,
    MaritalStatusPipe,
    MotherTonguePipe,
    OccupationPipe,
    PhysicalStatusPipe,
    FamilyStatusPipe,
    QualificationPipe,
    RaasiPipe,
    GrahamPipe,
    RelationPipe,
    ReligionPipe,
    ResidentStatusPipe,
    StarPipe,
    CountryPipe,
    StatePipe, 
    CityPipe,
    DisplayGrahamDirective
  ],
  providers:[

    // It must be in provider to use this pipe inside the component.ts
   RaasiPipe,
   GrahamPipe 
  ]

})
export class MasterDataModule {

  constructor(@Optional() @SkipSelf() parentModule?: MasterDataModule) {
    // if (parentModule) {
    //   throw new Error(
    //     'MasterDataModule is already loaded. Import it in the AppModule only');
    // }
  }
  static forRoot(): ModuleWithProviders<MasterDataModule> {
    return {
      ngModule: MasterDataModule,
      providers: [
        MasterDataService
      ]
    };
  }
}
