import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }



  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean> {
    const promise: Promise<boolean> = new Promise(
      (resolve: (res: boolean) => void, reject: (res: boolean) => void) => {
        // Check if basis details has beed already added by the user
        const dob = localStorage.getItem('dob');
        const religionId = localStorage.getItem('religionId');
        const qualificationId = localStorage.getItem('qualificationId');
        if (dob && religionId && qualificationId) {
          resolve(true);
        }
        else {
          // User hasnt completed their profile. So redirect to details page to update.
          this.router.navigate(['user/details/update/pending']);
          resolve(false);
        }
      }
    );

    return promise;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

}
