import { Pipe, PipeTransform } from '@angular/core';
import { Star } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'star'
})
export class StarPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private stars: Star[] = [{}];


  transform(starIds: number[] | number | undefined): string {

    /* Check if the star array has empty object */
    if (this.stars.length == 1) {
      this.masterDataService.star$.subscribe(stars => {
        this.stars = stars;
      })
    }
    if (!starIds) {
      // Undefined
      return "";
    }
    else if (typeof (starIds) === 'object') {
      let starString: string = '';
      // Array 
      starIds.forEach((motherTongue_id) => {
        const star = this.stars.filter(star => star.star_id === motherTongue_id);
        if (starString === '') {
          // Append without comma
          starString += star[0].star || ''
        } else {
          // Append with comma
          starString += ', ' + star[0].star || ''
        }


      });
      return starString;



    } else {
      // number

      const star = this.stars.filter(star => star.star_id == starIds)
      return star[0].star || '';
    }


  }

}
