<div class="mo-form-container" [ngClass]="screenInfo.cssClass">

  <div class="mo-lg-mn" *ngIf="showLoginOtpForm && contact_details">
    We have the following mobile number(s) in our database. <br />Kindly Enter one of these number to get otp
    <ul>
      <li *ngFor="let contact_detail of contact_details">
        {{contact_detail.mobile_no}}
      </li>
    </ul>
  </div>


  <div class="mo-form-field-error" *ngIf="showLoginOtpForm && showError">Mobile No is not valid </div>

  <div class="mo-form-field-error" *ngIf="showVerifyOtpForm && showError">Otp is either Invalid or Expired</div>
  <mat-card class="mo-ln-card" *ngIf="showLoginOtpForm">
    <mat-card-header>

      <!-- <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> -->
      <mat-card-title>{{'LOGIN_WITH_OTP' | translate }} </mat-card-title>

    </mat-card-header>
    <form [formGroup]="formLoginOtpGroup" autocomplete="off">
      <div class="mo-country-code">
        <mat-form-field>
          <mat-label>{{ 'USER.COUNTRY_CODE' | translate }}</mat-label>
          <input type="text" matInput formControlName="countryId" minlength="1" maxlength="7">
        </mat-form-field>
      </div>
      <div class="mo-mobile-no">
        <mat-form-field>
          <mat-label>{{ 'USER.MOBILE_NUMBER' | translate }}</mat-label>
          <input type="text" matInput formControlName="mobileNo" placeholder="" maxlength="10">
        </mat-form-field>

      </div>

      <div>
        <button mat-button mat-raised-button color="primary" type="submit" (click)="sendOtp()"
          [disabled]="!formLoginOtpGroup.valid">{{
          'LOGIN' | translate }} <app-spinner *ngIf="showLoginSpinner"></app-spinner></button>

      </div>
      <div class="mo-form-field-error"
        *ngIf="(formLoginOtpGroup.controls['mobileNo'].invalid && ( formLoginOtpGroup.controls['mobileNo'].touched)) &&formLoginOtpGroup.controls['mobileNo'].errors?.['pattern']">
        Mobile number should be numbers and length should be 10 characters.
      </div>
    </form>

  </mat-card>

  <mat-card class="mo-ln-card" *ngIf="showVerifyOtpForm">
    <mat-card-header>

      <!-- <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> -->
      <mat-card-title>{{'ENTER_YOUR_OTP' | translate }} </mat-card-title>

    </mat-card-header>
    <form [formGroup]="formVerifyOtpGroup" autocomplete="off">
      <div>
        <mat-form-field class="m-country-code">
          <mat-label>{{ 'ENTER_YOUR_OTP' | translate }}</mat-label>
          <input type="text" matInput formControlName="otp" minlength="6" maxlength="6">
        </mat-form-field>
      </div>

      <div>
        <button mat-button mat-raised-button color="primary" type="submit" (click)="verifyOtp()"
          [disabled]="!formVerifyOtpGroup.valid">{{
          'VERIFY' | translate }}
          <app-spinner *ngIf="showVerifySpinner"></app-spinner></button>
      </div>

      <div class="mo-form-field-error"
        *ngIf="(formVerifyOtpGroup.controls['otp'].invalid && ( formVerifyOtpGroup.controls['otp'].touched)) &&formVerifyOtpGroup.controls['otp'].errors?.['pattern']">
        OTP should be numbers and length should be 6 characters.
      </div>
    </form>

  </mat-card>

  <div *ngIf="showVerifyOtpForm" class="mo-ln-rs-link-caption">Didn't receive otp? <span (click)="sendOtp()"
      class="mo-link">Click here </span>to resend OTP.</div>
</div>