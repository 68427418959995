import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';

import { TranslateService } from '@ngx-translate/core';

import { MatSidenav } from '@angular/material/sidenav';
import { NgxPermissionsService } from 'ngx-permissions';
import { IScreensize } from 'src/app/app';
import { ScrollHandleService } from 'src/app/shared/services/scroll-handle.service';
import { routerTransition } from '../router.animations';

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
    selector: 'app-layout',
    animations: [routerTransition],
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    public userName: any = '';
    public userType: any = '';
    public config: any;
    private _router!: Subscription;
    public loading = false;
    public screenInfo!: IScreensize;
    /* This flag is used to hide the toolbar on the result page when user starts to scroll */
    public scrollTop: number = 0;
    /* This will hold the current scolling position  */
    public currentScrollTop: number = 0;
    mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
    url!: string;
    collapseSidebar = false;
    currentLang = 'en';
    sidePanelOpened = false;


    public hideFooterMenuForMobile: boolean = false;
    public searchResultsFound: boolean = false;

    @ViewChild('sidemenu', { static: false }) sidemenu!: MatSidenav;



    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public zone: NgZone,
        private cdr: ChangeDetectorRef,
        public translate: TranslateService,
        private ngxPermissions: NgxPermissionsService,
        private screensizeDetectService: ScreensizeDetectService,
        private scrollHandleService: ScrollHandleService
    ) {
        this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
            this.screenInfo = screenInfo;
        });

        /* Enable the flag to send the flag to the serach component when it reaches the bottom */
        this.scrollHandleService.searchResult$.subscribe(resultFound => {
            if (resultFound) {
                this.searchResultsFound = true;
            }
        })
    }

    ngOnInit(): void {

        this.url = this.router.url;
        

        this.route.url.subscribe((event) => {
            //console.log(event[0].path); 
        });

        this._router = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // this.loading = true;
            }
            if (event instanceof NavigationEnd) {
                this.url = event.url;
                this.runOnRouteChange();
                // this.loading = false;
            }
            if (event instanceof NavigationCancel ||
                event instanceof NavigationError) {
                // this.loading = true;
            }

        });
        // this.updateService.subscribeForUpdates();


    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.setUserName();
            // this.permissionsService.setPermissions([]);
        });
    }

    setUserName() {

        this.userName = localStorage.getItem('displayName');
    }

    ngOnDestroy(): void {
        this._router.unsubscribe();
    }

    runOnRouteChange(): void {
        
        if(this.url !== 'user/search'){
            this.hideFooterMenuForMobile = false;
        }
        if (this.isOver()) {
            //this.sidemenu.close();
        }
    }

    isOver(): boolean {
        this.collapseSidebar = false;
        return this.mediaMatcher.matches;
    }

    menuMouseOver(): void {
        if (this.mediaMatcher.matches && this.collapseSidebar) {
            this.sidemenu.mode = 'over';
        }
    }

    menuMouseOut(): void {
        if (this.mediaMatcher.matches && this.collapseSidebar) {
            this.sidemenu.mode = 'over';
        }
    }


    changePassword() {
        const url = this.config.changePassword;
        window.location.href = url;
    }

    editProfile() {
        const url = this.config.editProfile;
        window.location.href = url;
    }


    //getState(outlet) {
    getState() {
        //return outlet.activatedRouteData.animationType;
    }


    handleScroll(event: any) {
        // Handle scroll only for search page and if search results found
        if (this.searchResultsFound) {
            // if Search results found meaning, we are in seach results page. So hide the footer link from layout and introduce the footer from search component.
            this.hideFooterMenuForMobile = true;
            const targetElement = event.target;
            this.scrollTop = targetElement.scrollTop;

            //if(this.scrollTop > this.currentScrollTop + 300 ){
            // console.log('scrolling to next element');
            // this.currentScrollTop = this.currentScrollTop + 300;
            // targetElement.scrollTop = this.currentScrollTop;

            //}


            /* Check if 70% of scrolling has completed */
            const scrollEnd = targetElement.scrollTop > (targetElement.scrollHeight * 50 / 100)
            if (scrollEnd) {
                // if(scrollEnd){
                // Trigger Seach for the next result set
                this.scrollHandleService.triggerSearch();
                /* Reset the flag and wait for the notification from the search component, if they found new result set */
                this.searchResultsFound = false;

            } 
        }else{
            this.hideFooterMenuForMobile = false;
        }
    }

    

}

