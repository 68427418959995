<div class="mo-about-container">
    <h2> Frequently Asked Questions</h2>
    <div class="mo-about-section">
        <div>
            Q: What is Vaniya Chettiyar?
        </div>
        <div>
            A: Vaniya Chettiyar is an online matrimonial website based in India, offering a platform for individuals looking
            to find their soul mate.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: What kind of services does Vaniya Chettiyar offer?
        </div>
        <div>
            A: Vaniya Chettiyar offers advanced search filters, verified profiles, personalized match recommendations,
            privacy and security, and dedicated support.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: Is Vaniya Chettiyar free to use?
        </div>
        <div>
            A: Yes. All features and services of Vaniya Chettiyar are free to use with some limitions.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: How does Vaniya Chettiyar ensure privacy and security for its users?
        </div>

        <div>
            A: Vaniya Chettiyar takes great care to ensure that personal information is securely stored and protected
            against unauthorized access. All profiles are verified to ensure authenticity.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: Can I find matches from different languages and religions on Vaniya Chettiyar?
        </div>
        <div>
            A: Yes, Vaniya Chettiyar is a platform for individuals of all languages, religions, and castes in India.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: How can I contact Vaniya Chettiyar&#39;s support team?
        </div>
        <div>

            A: You can reach out to Vaniya Chettiyar&#39;s support team through the contact form on the website or by
            emailing help@mettioli.in.
        </div>
    </div>
    <div class="mo-about-section">
        <div>
            Q: What should I do if I encounter a fake profile on Vaniya Chettiyar?
        </div>
        <div>
            A: If you encounter a fake profile on Vaniya Chettiyar, please report it to the support team at support@mettioli.in immediately so
            that it can be removed.
        </div>
    </div>
</div>