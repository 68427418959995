import { ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from "@angular/router";
/* This file is used to retain the search results when the user click comes to search results pages using back button */

export class CustomRouteReuseStrategy implements RouteReuseStrategy { 
    private handlers: Map<Route, DetachedRouteHandle> = new Map();

    constructor() {}

    public shouldDetach(_route: ActivatedRouteSnapshot): boolean {
        return _route.routeConfig?.data?.['routeReuse'] === true;

        
    }

    public store(
        route: ActivatedRouteSnapshot,
        handle: DetachedRouteHandle
    ): void {
        if (!route.routeConfig) return;
        this.handlers.set(route.routeConfig, handle);
    }

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.handlers.get(route.routeConfig);
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
        if (!route.routeConfig || !this.handlers.has(route.routeConfig)) return null;
        return this.handlers.get(route.routeConfig)!;
    }

    public shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot
    ): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}