import { HttpContextToken, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

export const SKIP_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService
  ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

/* Check if the SKIP_TOKEN ISz PASSED FROM THE GET REQUEST. If yes, dont include token */
    if (req.context.get(SKIP_TOKEN)) {
      return next.handle(req);
    }
    var token = this.authService.getAuthToken();
    req = req.clone({
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      })
      // setHeaders: {
      //   'Content-Type': 'application/json',
      //   'Accept': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      //   Authorization: `Bearer ${token}`
      // }
    });

    return next.handle(req);
  }
}
