import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
//import { Qualification } from '../master-data';

@Pipe({
  name: 'qualification'
})
export class QualificationPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  //private qualifications: Qualification[] = [{}];
  private qualifications: any[] = [{}];


  transform(qualificationIds: number[] | number | undefined): string {
    /* Check if the qualification array has empty object */
    if (this.qualifications.length == 1) {
      this.masterDataService.qualification$.subscribe(qualifications => {
        this.qualifications = qualifications;
      })
    }

    if (!qualificationIds) {
      // Undefined
      return "";
    }
    else if (typeof (qualificationIds) === 'object') {
      let qualificationString: string = '';
      // Array 
      qualificationIds.forEach((qualificationId) => {
        const qualification = this.qualifications.filter(qualification => qualification.qualification_id === qualificationId);
        if (qualificationString === '') {
          // Append without comma
          qualificationString += qualification[0].qualification || ''
        } else {
          // Append with comma
          qualificationString += ', ' + qualification[0].qualification || ''
        }


      });
      return qualificationString;



    } else {
      // number

      const qualification = this.qualifications.filter(qualification => qualification.qualification_id == qualificationIds)
    return qualification[0].qualification || '';
    }
    
  }

}
