import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { GrahamPipe } from '../pipes/graham.pipe';

@Directive({
  selector: '[appDisplayGraham]'
})
export class DisplayGrahamDirective implements OnInit {

  @Input() appDisplayGraham: string | undefined = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private grahamPipe: GrahamPipe
  ) {}

  ngOnInit(): void {

    // CHECK IF THE NODE HAS SOME TEXT OR NOT
    if (this.appDisplayGraham) {
      // CHECK IF THE PARAM IS COMMA SERPARATED VALUES OR NOT
      if (this.appDisplayGraham.indexOf(',')) {
        const valuesArray = this.appDisplayGraham.split(',');
        this.appendValue(valuesArray);
      } else {
        this.appendValue(this.appDisplayGraham);
      }

    }

  }

  appendValue(value: string[] | string) {

    let textNode, spanEl;

    
    if (typeof value === 'string') {
      
      // String
      // Transform the id into respetive value 
      value = this.grahamPipe.transform(value);
      spanEl = this.renderer.createElement('span');
      textNode = this.renderer.createText(value);
      this.renderer.appendChild(spanEl, textNode);
      this.renderer.appendChild(this.el.nativeElement, spanEl);

    } else {
      // Array
      value.forEach(element => {

        // Transform the id into respetive value 
        element = this.grahamPipe.transform(element);
        spanEl = this.renderer.createElement('span');
        textNode = this.renderer.createText(element);
        this.renderer.appendChild(spanEl, textNode);
        this.renderer.appendChild(this.el.nativeElement, spanEl);

      });
    }



  }


}
