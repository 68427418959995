<div class="mo-about-container">
    <h2> About Vaniya Chettiyar Matrimony</h2>
    <div class="mo-about-section">
        Vaniya Chettiyar Matrimony is a trusted and respected name in the Indian matrimonial industry, offering a platform for
        individuals looking to find their soul mate. Our mission is to provide a seamless and efficient
        matchmaking experience to our users, regardless of language, religion, or caste.
    </div>
    <div class="mo-about-section">
        We understand the importance of finding the right partner and are committed to helping you find
        the one who will be your life partner. Our platform offers a wide range of features to make your
        search easier and more effective, including advanced search filters, verified profiles, and
        personalized match recommendations.
    </div>
    <div class="mo-about-section">
        With a team of dedicated and experienced professionals, we ensure that your privacy and security
        are of the utmost importance. We take great care to verify each profile and offer a safe and secure
        environment for you to find your match.
    </div>
    <div class="mo-about-section">
        At Vaniya Chettiyar, we believe that everyone deserves to find love and happiness, and we are dedicated to
        helping you achieve that goal. Whether you are looking for someone who shares your beliefs and
        values or someone who will complement your personality, we are here to help.
    </div>
    <div class="mo-about-section">
        Thank you for choosing Vaniya Chettiyar as your partner in the search for love. We look forward to helping
        you find your soul mate and begin a new chapter in your life.
    </div>
</div>