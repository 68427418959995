import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
import { Occupation } from '../master-data';

@Pipe({
  name: 'occupation'  
})
export class OccupationPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private occupations: Occupation[] = [{}];


  transform(occupation_id: number | undefined): string {

    if(!occupation_id){
      return "";
    }
      /* Check if the occupation array has empty object */
    if (this.occupations.length == 1) {
      this.masterDataService.occupation$.subscribe(occupations => {
        this.occupations = occupations;
      })
    }
    const occupation = this.occupations.filter(occupation => occupation.occupation_id == occupation_id )
    return occupation[0].occupation || '';
  }

}
