import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent implements OnInit {

    @Output() confirmAction = new EventEmitter<boolean>();
    //promptMessage: string;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
        //this.promptMessage = data.message;
    }

    ngOnInit() {
    }

    confirm(): void {
        this.confirmAction.emit(true);
    }

}
