import { Pipe, PipeTransform } from '@angular/core';
import { BodyType } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'bodyType'  
})
export class BodyTypePipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private bodyTypes: BodyType[] = [{}];


  transform(bodyTypeId: number | undefined): string {

    if(!bodyTypeId){
      return "";
    }
      /* Check if the bodyType array has empty object */
    if (this.bodyTypes.length == 1) {
      this.masterDataService.bodyType$.subscribe(bodyTypes => {
        this.bodyTypes = bodyTypes;
      })
    }
    const bodyType = this.bodyTypes.filter(bodyType => bodyType.body_type_id == bodyTypeId )
    return bodyType[0].body_type || '';
  }

}
