import { Pipe, PipeTransform } from '@angular/core';
import { ICity } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'city'
})
export class CityPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private cities: ICity[] = [];


  transform(city_id: number | undefined): string {

    if (!city_id) {
      return "";
    }
    /* Check if the city array has empty object */
    if (this.cities.length == 0) {
      this.masterDataService.city$.subscribe(cities => {
        this.cities = cities;
      })
    }
    const city = this.cities.filter(city => city.city_id == city_id)
    return city[0].city_name || '';
  }

}
