import { Injectable, Injector, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { NotificationComponent } from '../notification/notification.component';

export enum NotificationType {
  Info,
  Warn,
  Error
}
@Injectable()
export class NotificationService {
  private readonly duration: number = 5000;

  // ErrorHandler is created before providers so need Injector to get at dependencies
  constructor(private injector: Injector, private zone: NgZone) {}

  displayNotification(
    notification: string,
    typeOfNotification?: NotificationType,
    duration: number = this.duration,
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
    isCommonErrorMessage:boolean = true
  ): void {
    typeOfNotification = typeOfNotification || NotificationType.Info;
    const style = this.getClass(typeOfNotification);

    const snackbar = this.injector.get(MatSnackBar);
    let data: string[] | string = '';
    if(typeof(notification) === 'object'){
      data = Object.values(notification)
    }else{
      data = notification;
    }
    
    //console.log(valuesArray);
    this.zone.run(() => {
      snackbar.openFromComponent(NotificationComponent, {
        data: data,
        panelClass: [style],
        duration: duration,
        verticalPosition: verticalPosition
      });
    });
  }

  private getClass(typeOfNotification: NotificationType) {
    if (typeOfNotification === NotificationType.Info) {
      return 'mo-notification-info';
    } else if (typeOfNotification === NotificationType.Warn) {
      return 'mo-notification-warn';
    }
    return 'mo-notification-error';
  }
}
