<div class="mo-form-container">
    <div *ngIf="displayLogoutSuccessMsg">
        <span>You have been signed out from this device successfully.</span>
        <!-- <span class="mo-link" (click)="logoutFromAllDevices()">Click here</span><span> if you want to signout from all other devices.</span> -->
    </div>

    <div *ngIf="displayLogoutAllSuccessMsg">
        <span>You have been signed out from all the devices successfully.</span>
        <span class="mo-link">Click here</span><span> if you want to signout from all other devices.</span>
    </div>
</div>