import { Pipe, PipeTransform } from '@angular/core';
import { MaritalStatus } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'maritalStatus'  
})
export class MaritalStatusPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private maritalStatuss: MaritalStatus[] = [{}];


  transform(maritalStatusIds:  number[] | number | undefined): string {

    /* Check if the maritalStatus array has empty object */
    if (this.maritalStatuss.length == 1) {
      this.masterDataService.maritalStatus$.subscribe(maritalStatuss => {
        this.maritalStatuss = maritalStatuss;
      })
    }

    if(!maritalStatusIds){
      return "";
    }
    else if (typeof (maritalStatusIds) === 'object') {
      let maritalStatusString: string = '';
      console.log(maritalStatusIds);
      // Array 
      maritalStatusIds.forEach((motherTongue_id) => {
        const motherTongue = this.maritalStatuss.filter(maritalStatus => maritalStatus.marital_status_id === motherTongue_id);
        if (maritalStatusString === '') {
          // Append without comma
          maritalStatusString += motherTongue[0].marital_status || ''
        } else {
          // Append with comma
          maritalStatusString += ', ' + motherTongue[0].marital_status || ''
        }


      });
      return maritalStatusString;



    } else {
      // number
      const maritalStatus = this.maritalStatuss.filter(maritalStatus => maritalStatus.marital_status_id == maritalStatusIds )
      return maritalStatus[0].marital_status || '';
    }
      
    
  }

}
