import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IScreensize } from 'src/app/app';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';
import { UserService } from 'src/app/shared/services/user.service';

export interface IOtpDetails {
    expire_at?: string;
    verification_id: string;
}

@Component({
    selector: 'app-login-otp',
    templateUrl: './login-otp.component.html',
    styleUrls: ['./login-otp.component.scss']
})



export class LoginOtpComponent implements OnInit {

    public screenInfo!: IScreensize;
    formLoginOtpGroup!: FormGroup;
    formVerifyOtpGroup!: FormGroup;
    otpDetails!: IOtpDetails;
    showLoginOtpForm: boolean = true;
    showVerifyOtpForm: boolean = false;
    showError: boolean = false;
    userId!: string;
    contact_details!: any;
    public showLoginSpinner = false;
    public showVerifySpinner = false;

    constructor(
        private readonly fb: FormBuilder,
        public authService: AuthService,
        public userService: UserService,
        // public dialog: MatDialog,
        private readonly translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private screensizeDetectService: ScreensizeDetectService
    ) {
        this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
            this.screenInfo = screenInfo;
        });

    }

    ngOnInit() {
        
        let userId = this.route.snapshot.paramMap.get('userId');
        let verificationId = this.route.snapshot.paramMap.get('verificationId');

        /**
         *  If user id passed in the url, get the mobile numbers of that user 
         *  as they doesnt know which numbers have been added in our db 
         *  because their details were added by the admin 
        */
        if (userId) {
            this.getUserMobileNumbers(userId)
        }

        /**
         * if verification id is passed, then the otp had been sent to the user from another page eg: registration page
         * So show the verificaton form directly
         */
        if(verificationId){
            this.showLoginOtpForm = false;
            this.showVerifyOtpForm = true;

            this.otpDetails = { verification_id:  verificationId }  ;
        }

        //this.userDetails = this.authService.getUserDetails();
        this.setFormGroup();
    }

    setFormGroup() {

        this.formLoginOtpGroup = this.fb.group({
            countryId: ['91', [Validators.required, Validators.pattern('((1|44)-)?[1-9]{1,4}')]],
            mobileNo: ['', [Validators.required, Validators.pattern('[1-9][0-9]{9}')]],
        });

        this.formVerifyOtpGroup = this.fb.group({
            otp: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],

        });
    }
    /*
        This function is used to retreive the mobile numbers of given user id.
         Basically user id will be passed in the route only for the users who are registered by admin and not verified yet by user (During First time login). 
    */
    getUserMobileNumbers(userId: string) {
        this.userService.getUserMobileNumbers(userId).subscribe(response => {
            this.contact_details = response.contact_details;
        });

    }

    sendOtp() {
        this.showLoginSpinner = true;
        this.showError = false;
        if (this.authService.isDebugMode()) {
            this.authService.displayConsoleMessage(this.formLoginOtpGroup.value);
        }
        const userLoginDetailsJson = {

            country_id: this.formLoginOtpGroup.get('countryId')!.value,
            mobile_no: this.formLoginOtpGroup.get('mobileNo')!.value,

        };

        // Check if one of the fields in the form group has been modified
        if (this.formLoginOtpGroup.dirty && this.formLoginOtpGroup.valid) {

            // Insert User DETAILS
            this.userService.generateOtp(userLoginDetailsJson).subscribe(response => {
                this.showLoginSpinner = false;
                if (response && response.data) {
                    this.otpDetails = response.data;
                    this.showLoginOtpForm = false;
                    this.showVerifyOtpForm = true;
                } else if (response && response.error) {
                    // Mobile number is not valid
                    if (response.error === 'NOT_VALID_MOBILE_NO') {
                        this.showError = true;

                    }
                }

            });


        }
    }

    verifyOtp() {
        this.showVerifySpinner = true;
        
        if (this.authService.isDebugMode()) {
            this.authService.displayConsoleMessage(this.formVerifyOtpGroup.value);
        }
        const userLoginDetailsJson = {

            otp: this.formVerifyOtpGroup.get('otp')!.value,


        };

        // Check if one of the fields in the form group has been modified
        if (this.formVerifyOtpGroup.dirty && this.formVerifyOtpGroup.valid) {

            // Insert User DETAILS
            this.userService.verifyOtp(userLoginDetailsJson, this.otpDetails.verification_id).subscribe(response => {
                this.showVerifySpinner = false;
                if (response && response.data && response.data.token) {
                    // Remove all the other user details set by previous login if any
                    this.authService.clearUserDetails();
                    
                    // Save authorize token in local storage                
                    this.authService.setAuthToken(response.data.token);
                    
                    this.authService.setUserDetails(response.data);
                    this.router.navigate(['user/details/update/pending']);

                }
                else if (response && response.error) {
                    // Mobile number is not valid
                    if (response.error === 'OTP_INVALID') {
                        this.showError = true;

                    }
                }



            });


        }
    }

}
