import { Pipe, PipeTransform } from '@angular/core';
import { IState } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private states: IState[] = [];


  transform(state_id: number | undefined): string {

    if (!state_id) {
      return "";
    }
    /* Check if the state array has empty object */
    if (this.states.length == 0) {
      this.masterDataService.state$.subscribe(states => {
        this.states = states;
      })
    }
    const state = this.states.filter(state => state.state_id == state_id)
    return state[0].state_name || '';
  }

}
