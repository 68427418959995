import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(
    //public authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* Only locals are has the file extension of .json. 
      Add header only for local files.
    */
    if (req.url.indexOf('.json') !== -1) {
      const headers = req.headers;
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
    }
    return next.handle(req);
  }
}
