<div class="">

    <div #customHeader>
        <ng-content select=".standard-page-custom-header"></ng-content>
    </div>

    <div class="content-title-bar" [ngClass]="{'shrinkHeader': shrinkHeader}" [ngStyle]=" {'display': showCustomHeader ? 'none' : 'block'}">
        <div class="back-button vertical" *ngIf="showBackLink || showHomeLink">
            <button mat-icon-button *ngIf="showBackLink" ngClass="l" ngClass.gt-sm="xl" aria-label="Navigate back" (click)="back()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button *ngIf="showHomeLink" ngClass="l" aria-label="Navigate home" [routerLink]="['/']">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
        </div>

        <div class="vertical" *ngIf="titleIcon">
            <mat-icon class="title-icon">{{ titleIcon }}</mat-icon>
        </div>

        <div fxFlex="90" class="title">
            <div class="vertical">
                <h1 *ngIf="title">{{title}}</h1>
                <div class="sub-heading" *ngIf="subHeading">
                    {{subHeading}}
                </div>
            </div>
        </div>

        <div class="mobile-hidden" style="flex-grow:0">
            <ng-content select=".standard-page-toolbar-buttons"></ng-content>
        </div>
        

        <div class="action-button vertical desktop-hidden">
            <div [hidden]="!showActionMenu">
                <button mat-icon-button ngClass="l" [mat-menu-trigger-for]="pageActions" aria-label="Open page actions menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #pageActions x-position="before">
                    <div #actionMenuItems>
                        <ng-content select=".standard-page-actions"></ng-content>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="page-content" [ngClass]="{'shrinkHeader': shrinkHeader}" (scroll)="onScroll($event)">
        <ng-content select=".standard-page-content"></ng-content>
    </div>
</div>

