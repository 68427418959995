import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISearchPaginationResults } from 'src/app/user-details-form/user-details';
//import { environment } from '../../environments/environment';
import { SKIP_TOKEN } from 'src/app/core/interceptors/auth.interceptors';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {

  private userIdSource = new BehaviorSubject<string | null>(null);
  userId$ = this.userIdSource.asObservable();


  //private getAlerterviceUrl = environment.apiUrl + 'Alert/GetAllAlerts';
  private registerServiceUrl = environment.apiUrl + 'register';
  private registerByAdminServiceUrl = environment.apiUrl + 'registerByAdmin';
  private updateServiceUrl = environment.apiUrl + 'userDetails';
  private userCommentUrl = environment.apiUrl + 'userComments';
  private loginServiceUrl = environment.apiUrl + 'login';
  private logoutServiceUrl = environment.apiUrl + 'logout';
  private logoutAllServiceUrl = environment.apiUrl + 'logout/all';
  private loginOtpServiceUrl = environment.apiUrl + 'login';
  private loginOtpGenerateServiceUrl = environment.apiUrl + 'login/otp/generate';
  private loginVerifyOtpServiceUrl = environment.apiUrl + 'login/otp/verify';
  private getUserMobileNumbersUrl = environment.apiUrl + 'getUserMobileNumbers';
  private searchServiceUrl = environment.apiUrl + 'search';
  private viewProfileUrl = environment.apiUrl + 'userDetails';
  private viewProfileForGuestUrl = environment.apiUrl + 'userDetailsForGuest';
  // private getUserHoroscopeUrl = environment.apiUrl + 'getUserHoroscope';
  private userHoroscopeDetailsUrl = environment.apiUrl + 'userHoroscopeDetails';
  private userHoroscopeDetailsForGuestUrl = environment.apiUrl + 'userHoroscopeDetailsForGuest';
  private userPhotosUrl = environment.apiUrl + 'userPhotos';
  private userPhotosGuestUrl = environment.apiUrl + 'userPhotosForGuest';
  private getPrimaryPhotoIdUrl = environment.apiUrl + 'getPrimaryPhotoId';
  private getPrimaryPhotoDetailsUrl = environment.apiUrl + 'getPrimaryPhotoDetails';
  private getPrimaryPhotoDetailsForGuestUrl = environment.apiUrl + 'getPrimaryPhotoDetailsForGuest';
  private setUserPrimaryPhotosUrl = environment.apiUrl + 'setPrimaryPhoto';
  private savePrimaryPhotoPositionUrl = environment.apiUrl + 'savePrimaryPhotoPosition';
  private userContactDetailsUrl = environment.apiUrl + 'userContactDetails';
  private userCVHCountUrl = environment.apiUrl + 'getCVHCountForCurrentMonth';
  private userLocationDetailsUrl = environment.apiUrl + 'userLocationDetails';
  private userLocationDetailsForGuestUrl = environment.apiUrl + 'userLocationDetailsForGuest';

  private userFavouriteProfileUrl = environment.apiUrl + 'userFavouriteProfiles';
  private userBlockedProfileUrl = environment.apiUrl + 'userBlockedProfiles';
  private userIgnoredProfileUrl = environment.apiUrl + 'userIgnoredProfiles';
  private userInterestsUrl = environment.apiUrl + 'userInterests';
  private userPartnerPreferenceUrl = environment.apiUrl + 'userPartnerPreferences';
  private userPartnerPreferenceForGuestUrl = environment.apiUrl + 'userPartnerPreferencesForGuest';


  private getUserFavouriteProfileUrl = environment.apiUrl + 'getUserFavouriteProfiles';
  private getUsersWhoLikesMeUrl = environment.apiUrl + 'getUsersWhoLikesMe';
  private getUserViewHistoryUrl = environment.apiUrl + 'getUserViewHistory';
  private getUsersWhoViewedMeUrl = environment.apiUrl + 'getUsersWhoViewedMe';
  private getUserContactViewHistoryUrl = environment.apiUrl + 'getUserContactViewHistory';
  private getUsersWhoViewedMyContactUrl = environment.apiUrl + 'getUsersWhoViewedMyContact';
  private getUserInterestedProfilesUrl = environment.apiUrl + 'getUserInterestedProfiles';
  private getUsersWhoSendInterestsUrl = environment.apiUrl + 'getUsersWhoSendInterests';
  private getCommunityProfilesUrl = environment.apiUrl + 'getCommunityProfiles';
  private getInterestReceivedFromUserUrl = environment.apiUrl + 'getInterestReceivedFromUser';
  private actOnUserInterestUrl = environment.apiUrl + 'actOnUserInterestUrl';


  //public isBackEndUser: boolean = false;
  constructor(
    private readonly http: HttpClient,

  ) {
    this.getScrollingElement();
    document.addEventListener('scroll', () => {
      this.getScrollingElement();
    })

    //this.setBackendUser();
  }

  /** This is primarily used by admin pages to get the user id from its child to set the navigation links */
  setCurrentUserId(user_id: string | null) {
    this.userIdSource.next(user_id)
  }

  /* This property is set only for backend user and front end user does not know about this property */
  // setBackendUser() {
  //   this.isBackEndUser = (this.getLocalStorageValue('beu')) ? true : false;
  // }

  /**
   *  Tells whether the current user is a backend or not
   * @returns bool
   */
  isBackendUser(){
    return this.getLocalStorageValue('beu');

  }

  



  /* Registraion */

  registerUser(data: object): Observable<any> {

    return this.http.post<any>(this.registerServiceUrl, data);
  }
  registerUserByAdmin(data: object): Observable<any> {

    return this.http.post<any>(this.registerByAdminServiceUrl, data);
  }


  updateUser(data: object, user_id: string): Observable<any> {

    return this.http.put<any>(this.updateServiceUrl + '/' + user_id, data);
  }


  /* Login */

  loginUser(data: object): Observable<any> {

    return this.http.post<any>(this.loginServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  generateOtp(data: object): Observable<any> {

    return this.http.post<any>(this.loginOtpGenerateServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }

  getUserMobileNumbers(userId: string): Observable<any> {
    return this.http.get(this.getUserMobileNumbersUrl + '/' + userId);
  }

  verifyOtp(data: object, verfication_id: string): Observable<any> {

    return this.http.post<any>(this.loginVerifyOtpServiceUrl + '/' + verfication_id, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  /* Logout */

  logoutUser(): Observable<any> {

    return this.http.get(this.logoutServiceUrl);
  }

  logoutUserFromAllDevices(): Observable<any> {

    return this.http.get(this.logoutAllServiceUrl);
  }



  /* Search */

  searchUser(data: object, currentPage: number): Observable<ISearchPaginationResults> {

    const searchServiceUrl = this.searchServiceUrl + '?page=' + currentPage;
    return this.http.post<any>(searchServiceUrl, data);
  }



  /* View Profile */

  getUserDetailsById(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the profile details else the owner
    const viewProfileUrl = (user_id !== null) ? this.viewProfileUrl + '/' + user_id : this.viewProfileUrl;

    return this.http.get(viewProfileUrl);
  }


  getUserDetailsByIdForGuest(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the profile details else the owner
    const viewProfileForGuestUrl = (user_id !== null) ? this.viewProfileForGuestUrl + '/' + user_id : this.viewProfileForGuestUrl;

    return this.http.get(viewProfileForGuestUrl);
  }


  

  // getMyDetails(): Observable<any> {
  //   return this.http.get(this.viewProfileUrl);
  // }





  /*  Photos  */

  getUserPhotos(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const userPhotosUrl = (user_id !== null) ? this.userPhotosUrl + '/' + user_id : this.userPhotosUrl;

    return this.http.get(userPhotosUrl);
  }

  getUserPhotosForGuest(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const userPhotosGuestUrl = (user_id !== null) ? this.userPhotosGuestUrl + '/' + user_id : this.userPhotosGuestUrl;

    return this.http.get(userPhotosGuestUrl);
  }

  getUserPrimaryPhotoId(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getPrimaryPhotoUrl = (user_id !== null) ? this.getPrimaryPhotoIdUrl + '/' + user_id : this.getPrimaryPhotoIdUrl;

    return this.http.get(getPrimaryPhotoUrl);
  }

  getUserPrimaryPhotoDetails(user_id: string | null): Observable<any> {

    const getPrimaryPhotoDetailsUrl = (user_id !== null) ? this.getPrimaryPhotoDetailsUrl + '/' + user_id : this.getPrimaryPhotoDetailsUrl;

    return this.http.get(getPrimaryPhotoDetailsUrl);
  }

  getUserPrimaryPhotoDetailsForGuest(user_id: string | null): Observable<any> {

    const getPrimaryPhotoDetailsForGuestUrl = (user_id !== null) ? this.getPrimaryPhotoDetailsForGuestUrl + '/' + user_id : this.getPrimaryPhotoDetailsForGuestUrl;

    return this.http.get(getPrimaryPhotoDetailsForGuestUrl);
  }

  setUserPrimaryPhoto(params: any) {
    return this.http.post(this.setUserPrimaryPhotosUrl, params);
  }

  updateUserPhoto(data: any, user_id: string): Observable<any> {

    return this.http.post(this.userPhotosUrl, data, {
      reportProgress: true,
      observe: 'events'
    })

  }

  deletePhoto(photo_id: string) {
    return this.http.delete(this.userPhotosUrl + '/' + photo_id);
  }

  savePrimaryPhotoPosition(photo_id: string, position: number, view_user_id: string | null)  {
    const params = {
      user_photo_id: photo_id,
      v_position: position,
      view_user_id: view_user_id
    }

    return this.http.post(this.savePrimaryPhotoPositionUrl, params);
  }


  /*  Horoscope */

  getUserHoroscope(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the horoscope details else the owner
    const userHoroscopeDetailsUrl = (user_id) ? this.userHoroscopeDetailsUrl + '/' + user_id : this.userHoroscopeDetailsUrl;

    return this.http.get(userHoroscopeDetailsUrl);
  }


  getUserHoroscopeForGuest(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the horoscope details else the owner
    const userHoroscopeDetailsForGuestUrl = (user_id) ? this.userHoroscopeDetailsForGuestUrl + '/' + user_id : this.userHoroscopeDetailsForGuestUrl;

    return this.http.get(userHoroscopeDetailsForGuestUrl);
  }


  updateUserHoroscopeDetails(data: any, user_id: string | null): Observable<any> {

    // For put request User id is mandatory. So get the current user id from localstorage and send it
    if(!user_id){
      user_id = this.getLocalStorageValue('uid');
    }
    //const userHorolscopeDetailsUrl = (user_id) ? this.userHorolscopeDetailsUrl + '/' + user_id : this.userHorolscopeDetailsUrl;
    return this.http.put<any>(this.userHoroscopeDetailsUrl + '/' + user_id, data);

  }



  /* Location Details */


  getUserLocationDetails(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userLocationDetailsUrl = (user_id) ? this.userLocationDetailsUrl + '/' + user_id : this.userLocationDetailsUrl;

    return this.http.get(userLocationDetailsUrl);
  }


  getUserLocationDetailsForGuest(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userLocationDetailsForGuestUrl = (user_id) ? this.userLocationDetailsForGuestUrl + '/' + user_id : this.userLocationDetailsForGuestUrl;

    return this.http.get(userLocationDetailsForGuestUrl);
  }

  updateUserLocationDetails(data: any, user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userLocationDetailsUrl = (user_id) ? this.userLocationDetailsUrl + '/' + user_id : this.userLocationDetailsUrl;
    return this.http.put<any>(userLocationDetailsUrl, data);
  }




  /*  Contact Details */

  getCVHCountForCurrentMonth(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userCVHCountUrl = (user_id) ? this.userCVHCountUrl + '/' + user_id : this.userCVHCountUrl;
    return this.http.get(userCVHCountUrl);
  }

  getUserContactDetails(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userContactDetailsUrl = (user_id) ? this.userContactDetailsUrl + '/' + user_id : this.userContactDetailsUrl;
    return this.http.get(userContactDetailsUrl);
  }

  updateUserContactDetails(data: any, user_id: string): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userContactDetailsUrl = (user_id) ? this.userContactDetailsUrl + '/' + user_id : this.userContactDetailsUrl;

    return this.http.put<any>(userContactDetailsUrl, data);
  }


  /* 
    Partner Preferences
  */
    getUserPartnerPreferences(user_id: string | null): Observable<any> {
      // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userPartnerPreferenceUrl = (user_id) ? this.userPartnerPreferenceUrl + '/' + user_id : this.userPartnerPreferenceUrl;

    return this.http.get(userPartnerPreferenceUrl);
    }


    getUserPartnerPreferencesForGuest(user_id: string | null): Observable<any> {
      // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userPartnerPreferenceForGuestUrl = (user_id) ? this.userPartnerPreferenceForGuestUrl + '/' + user_id : this.userPartnerPreferenceForGuestUrl;

    return this.http.get(userPartnerPreferenceForGuestUrl);
    }
    
    updateUserPartnerPreference(data: any, user_id: string | null): Observable<any> {
      // If user id passed, then other users or back end users are accessing the contact details else the owner
      const userPartnerPreferenceUrl = (user_id) ? this.userPartnerPreferenceUrl + '/' + user_id : this.userPartnerPreferenceUrl;
      //const userPartnerPreferenceUrl =  this.userPartnerPreferenceUrl;
  
      return this.http.put<any>(userPartnerPreferenceUrl, data);

      
    }

  /* User Comments */
  updateUserComment(data: object, user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the comment else the owner
    const userCommentUrl = (user_id) ? this.userCommentUrl + '/' + user_id : this.userCommentUrl;

    return this.http.put<any>(userCommentUrl, data);
  }

  getUserComment(user_id: string | null): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const userCommentUrl = (user_id) ? this.userCommentUrl + '/' + user_id : this.userCommentUrl;
    return this.http.get(userCommentUrl);
  }


  /**
   * 
   * @param user_id number user_id of user who sent interest to the owner
   * @returns 
   */
  getInterestReceivedFromUser(user_id: string): Observable<any> {
    // If user id passed, then other users or back end users are accessing the contact details else the owner
    const getInterestReceivedFromUserUrl =  this.getInterestReceivedFromUserUrl + '/' + user_id;
    return this.http.get(getInterestReceivedFromUserUrl);
  }

  actOnUserInterest(isAccepted:boolean, view_user_id:string){
    const params = {
      is_accepted: isAccepted,
      view_user_id: view_user_id,
    }

    return this.http.post(this.actOnUserInterestUrl, params);

  }



  /**
   * Ger user Favourite Profiles 
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUserFavourites(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUserFavouriteProfileUrl = (user_id !== null) ? this.getUserFavouriteProfileUrl + '/' + user_id : this.getUserFavouriteProfileUrl;

    return this.http.get(getUserFavouriteProfileUrl);
  }



  /**
   * Get all the users who likes me means people who has set my profile as their favourites.
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUsersWhoLikeMe(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUsersWhoLikesMeUrl = (user_id !== null) ? this.getUsersWhoLikesMeUrl + '/' + user_id : this.getUsersWhoLikesMeUrl;

    return this.http.get(getUsersWhoLikesMeUrl);
  }


  /**
   * Ger user Interest Sent
   * Here we dont use Interest profiles Resource as we actually need basic detail info in the result
   *  */
  getUserInterestsSent(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the details else the owner
    const getUserInterestedProfilesUrl = (user_id !== null) ? this.getUserInterestedProfilesUrl + '/' + user_id : this.getUserInterestedProfilesUrl;

    return this.http.get(getUserInterestedProfilesUrl);
  }



  /**
   * Get all the users who sent interests to the user(owner).
   * Here we dont use Interest profiles Resource as we actually need basic detail info in the result
   *  */
  getUsersInterestsReceived(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the details else the owner
    const getUsersWhoSendInterestsUrl = (user_id !== null) ? this.getUsersWhoSendInterestsUrl + '/' + user_id : this.getUsersWhoSendInterestsUrl;

    return this.http.get(getUsersWhoSendInterestsUrl);
  }
  /**
   * Get all My View History.
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUserViewHistory(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUserViewHistoryUrl = (user_id !== null) ? this.getUserViewHistoryUrl + '/' + user_id : this.getUserViewHistoryUrl;

    return this.http.get(getUserViewHistoryUrl);
  }


  /**
   * Get all the users who viewed my profile.
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUsersWhoViewedMe(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUsersWhoViewedMeUrl = (user_id !== null) ? this.getUsersWhoViewedMeUrl + '/' + user_id : this.getUsersWhoViewedMeUrl;

    return this.http.get(getUsersWhoViewedMeUrl);
  }


  /**
   * Get all My contact View History
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUserContactViewHistory(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUserContactViewHistoryUrl = (user_id !== null) ? this.getUserContactViewHistoryUrl + '/' + user_id : this.getUserContactViewHistoryUrl;

    return this.http.get(getUserContactViewHistoryUrl);
  }


  /**
   * Get all the users who viewed my conact no.
   * Here we dont use Favourite profiles Resource as we actually need basic detail info in the result
   *  */
  getUsersWhoViewedMyContact(user_id: string | null): Observable<any> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getUsersWhoViewedMyContactUrl = (user_id !== null) ? this.getUsersWhoViewedMyContactUrl + '/' + user_id : this.getUsersWhoViewedMyContactUrl;

    return this.http.get(getUsersWhoViewedMyContactUrl);
  }

   /**
   * Get all the users (Male / Female)from Vaniya Chettiyar.
   * 
   *  */
   getCommunityProfiles(data: object, currentPage: number): Observable<ISearchPaginationResults> {

    // If user id passed, then other users or back end users are accessing the photos else the owner
    const getCommunityProfilesUrl =  this.getCommunityProfilesUrl + '?page=' + currentPage;
    return this.http.post<any>(getCommunityProfilesUrl, data);
  }

  

  markAsFavouriteProfile(params: any): Observable<any> {

    return this.http.post<any>(this.userFavouriteProfileUrl, params);
  }

  markAsBlockedProfile(params: any): Observable<any> {

    return this.http.post<any>(this.userBlockedProfileUrl, params);
  }

  markAsIgnoredProfile(params: any): Observable<any> {

    return this.http.post<any>(this.userIgnoredProfileUrl, params);
  }

  sendInterestToProfile(params: any): Observable<any> {

    return this.http.post<any>(this.userInterestsUrl, params);
  }
  

  // updateUserContactDetails(user_id:number): Observable<any> {
  //   return this.http.get(this.userContactUrl + '/' + user_id);
  // }

  getScrollingElement(): Element {
    // console.log('scroll element');
    // console.log(document.scrollingElement);
    return document.scrollingElement || document.documentElement;
  }

  setLocalStorageValue(field: string, value: string) {
    return localStorage.setItem(field, value);
  }
  getLocalStorageValue(field: string) {
    return localStorage.getItem(field);
  }



}
