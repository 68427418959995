<div class="mo-about-container">

    <h2> Privacy Policy</h2>
    <div class="mo-about-section">
        By using Vaniya Chettiyar, you acknowledge that you have read, understood, and agreed to these terms and
        conditions. If you have any questions, please contact our support team.
    </div>
    <div class="mo-about-section">
        1. Information Collected: Vaniya Chettiyar collects personal information such as names, email
        addresses, contact numbers, date of birth, location, and demographic information from
        users when they register for our platform. We also collect information about user activity on
        the site, including the profiles viewed, messages sent, and preferences selected.
    </div>
    <div class="mo-about-section">
        2. Use of Information: Vaniya Chettiyar uses the information collected to provide a personalized
        experience for users, including match recommendations, targeted advertising, and regular
        updates. We also use the information to improve the site, develop new features and
        services, and for research purposes.
    </div>
    <div class="mo-about-section">
        3. Sharing of Information: Vaniya Chettiyar does not sell or rent personal information to third parties.
        We may share aggregated demographic information with partners for research purposes. In
        the event of a merger or acquisition, personal information may be transferred to the new
        owners. Vaniya Chettiyar may also share personal information with third parties if required to do so
        by law or if necessary to enforce our policies or protect the rights, property, or safety of our
        users or others.
    </div>
    <div class="mo-about-section">
        4. Cookies: Vaniya Chettiyar uses cookies to personalize user experience and to track user activity on
        the site. Cookies are small text files that are stored on your device when you visit our site.
        You can choose to disable cookies in your browser settings, but doing so may affect the
        functionality of the site.
    </div>
    <div class="mo-about-section">
        5. Security: Vaniya Chettiyar takes great care to ensure that personal information is securely stored and
        protected against unauthorized access. We use industry-standard encryption and other
        security measures to protect user information.
    </div>
    <div class="mo-about-section">
        6. Data Retention: Vaniya Chettiyar retains personal information for as long as it is necessary to provide
        the services requested by the user or as required by law.
    </div>
    <div class="mo-about-section">
        7. Access to Personal Information: Users may access, correct, or update their personal
        information at any time through their account settings. If a user requests that their
        information be deleted, Vaniya Chettiyar will take reasonable steps to do so, but may retain some
        information for record-keeping purposes or as required by law.
    </div>
    <div class="mo-about-section">
        8. Notification of Changes: Vaniya Chettiyar will notify users of any material changes to this Privacy
        Policy through a prominent notice on the site or by email.
    </div>
    <div class="mo-about-section">
        9. Children&#39;s Privacy: Vaniya Chettiyar does not knowingly collect personal information from children
        under the age of 18.
    </div>
    <div class="mo-about-section">
        10. International Users: Vaniya Chettiyar is based in India and personal information may be processed in
        India or any other country in which Vaniya Chettiyar or its affiliates or service providers maintain
        facilities.
    </div>
    <div class="mo-about-section">
        11. Third-Party Sites: Vaniya Chettiyar may contain links to third-party sites. We are not responsible for
        the privacy practices of these sites and encourage users to read their privacy policies.
    </div>
    <div class="mo-about-section">
        12. Complaints: If a user has a complaint regarding the handling of their personal information,
        they may contact Vaniya Chettiyar at help@mettioli.in.
    </div>
    <div class="mo-about-section">
        13. Enforceability: If any part of this Privacy Policy is deemed unenforceable, the enforceability
        of the remaining parts will not be affected.
    </div>
    <div class="mo-about-section">
        14. Choice of Law: This Privacy Policy shall be governed by and construed in accordance with the
        laws of India.
    </div>
    <div class="mo-about-section">
        15. Contact Us: If you have any questions or concerns about our Privacy Policy, please contact
        our support team at help@mettioli.in.
        By using Vaniya Chettiyar, you acknowledge that you have read, understood, and agreed to this Privacy
        Policy. If you have any questions or concerns, please contact our support team.
    </div>

</div>