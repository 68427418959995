

<div class="app mat-typography">
    <mat-toolbar class="mo-main-header  mat-elevation-z6">
        <div class="mo-logo mo-logo13">
            <!-- <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1> -->
            <a [routerLink]="['/landing']"><img src="../../../assets/images/logo-sm.png" alt="Vaniya Chettiyar" width="134"/></a>
        </div>
        <div fxFlex>
        </div>
        
        <div>
            <a mat-button [routerLink]="['/new-user/signup']">
                <mat-icon>person_add</mat-icon>
                {{'SIGNUP' | translate }}
            </a>

            <a mat-button [routerLink]="['/new-user/login']">
                <mat-icon>login</mat-icon>
                {{'LOGIN' | translate }}
            </a>
        </div>

        

    </mat-toolbar>
    <div class="mo-layout-body-container">  
        <router-outlet></router-outlet>
    </div>

    <section class="mo-lp-footer">
        <ul>
            <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
            <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
            <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
            <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li>
            <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
        </ul>
        <div class="mo-lp-cr">&copy; 2023 Vaniya Chettiyar Matrimony, All rights reserved.</div>
    </section>
</div>
