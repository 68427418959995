import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IScreensize } from 'src/app/app';

@Injectable({
  providedIn: 'root'
})
export class ScreensizeDetectService {
  private isScreenSizeSubject = new BehaviorSubject<IScreensize>({cssClass: 'mo-xl', screenSize:'xl'});
  screenSize$ = this.isScreenSizeSubject.asObservable();
  
  constructor(
    private breakpointObserver: BreakpointObserver
  ) {

    /* Check if the device is mobile */
    
    this.breakpointObserver.observe([Breakpoints.XSmall,  Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge,  Breakpoints.HandsetPortrait]).subscribe(result => {
      // Todo: temporarily we added if else to return if mobile or not. Later we need to add breakpoint observer for each device
      
      if(result.matches){
        this.isScreenSizeSubject.next({cssClass: 'mo-xs', screenSize:'xs'});
      }else{
        this.isScreenSizeSubject.next({cssClass: 'mo-xl', screenSize:'xl'});
      } 


      if (result.breakpoints[Breakpoints.XSmall]) {
        // handle XSmall breakpoint
        this.isScreenSizeSubject.next({cssClass: 'mo-xs', screenSize:'xs'});
       }
       else if (result.breakpoints[Breakpoints.Small]) {
        // handle Small breakpoint
        this.isScreenSizeSubject.next({cssClass: 'mo-sm', screenSize:'sm'});
       }
       else if (result.breakpoints[Breakpoints.Medium]) {
       // handle Medium breakpoint
       this.isScreenSizeSubject.next({cssClass: 'mo-md', screenSize:'md'});
       }
       else if (result.breakpoints[Breakpoints.Large]) {
         // handle Large breakpoint
         this.isScreenSizeSubject.next({cssClass: 'mo-lg', screenSize:'lg'});
       }
       else if (result.breakpoints[Breakpoints.XLarge]) {
         // handle XLarge breakpoint
         this.isScreenSizeSubject.next({cssClass: 'mo-xl', screenSize:'xl'});
       }
       else if(result.breakpoints[Breakpoints.HandsetPortrait]){
        //this.isScreenSizeSubject.next({cssClass: 'mo-xs', screenSize:'xs'});
       }
      //  else if(result.breakpoints[Breakpoints.HandsetPortrait]){
      //   this.isScreenSizeSubject.next({cssClass: 'mo-xs', screenSize:'xs'});
      //  }
    });

    /* We can add for other screen size later if required */
    /* Angular15 does not support Angular FlexLayout. So we need to use this to detect size and apply css*/
  }

  // public isMobile(): boolean {
  //   return this.isScreenSizeSubject.value;
  // }
}
