<div class="app mat-typography" [ngClass]="screenInfo.cssClass">
    <!-- <mat-toolbar class="m-main-header  mat-elevation-z6" *ngIf="!searchResultsFound || (scrollTop < 100 && searchResultsFound)"> -->
        <!-- Dont display the header for search results screen -->
        <!-- <mat-toolbar class="mo-main-header  mat-elevation-z6" *ngIf="!searchResultsFound"> -->
        <mat-toolbar class="mo-main-header  mat-elevation-z6" >
        <!-- <button (click)="sidemenu.toggle()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button> -->
        <div class="mo-logo">
            <!-- <img src="../../../assets/images/mettioli_logo_dark.jpeg" width="220"/> -->
        </div>
        <div class="mo-logo mo-logo13">
            <!-- <span>me</span><span class="mo-first">t</span><span class="mo-mirror">t</span><span
                class="mo-end-text">ioli</span> -->

            <!-- <div class="mo-logo-caption">One Nation!! One Matrimony!!</div> -->
            <img src="../../../assets/images/logo-sm.png" alt="Vaniya Chettiyar" width="134"/>
        </div>

        <!-- Menu for larger devices other than mobile -->
        <div fxFlex *ngIf="screenInfo.screenSize !== 'xs'">
           
            <a mat-button [routerLink]="['/user/home']">
                <mat-icon>dashboard</mat-icon>
                {{'USER.MY_DASHBOARD' | translate }}
            </a>

            <a mat-button [routerLink]="['/user/search']">
                <mat-icon>person_search</mat-icon>
                {{'USER.SEARCH' | translate }}
            </a>


        </div>
        
        <div *ngIf="screenInfo.screenSize !== 'xs'" class="mo-name">
            {{userName}}
        </div>


        <button [mat-menu-trigger-for]="userMenu" mat-icon-button class="ml-xs" *ngIf="screenInfo.screenSize !== 'xs'">
            <mat-icon>person</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu" x-position="before">

            <!-- <a mat-menu-item [routerLink]="['/user/search/view']">
                <mat-icon>contact_page</mat-icon>
                {{'USER.VIEW_MY_PROFILE' | translate }}
            </a>


            <a mat-menu-item [routerLink]="['/user/details/update']">
                <mat-icon>edit_square</mat-icon>
                {{'USER.EDIT_MY_PROFILE' | translate }}
            </a>

            <a mat-menu-item [routerLink]="['/user/logout']">
                <mat-icon>manage_accounts</mat-icon>
                {{'USER.MY_SETTINGS' | translate }}
            </a> -->

            <a mat-menu-item [routerLink]="['/new-user/logout']">
                <mat-icon>exit_to_app</mat-icon>
                {{'SIGN_OUT' | translate }}
            </a>

            <button (click)="changePassword()" mat-menu-item *ngxPermissionsOnly="['CHANGE_PASSWORD']">
                <mat-icon>lock_open</mat-icon>
                {{'CHANGE_PASSWORD' | translate }}
            </button>
            <button (click)="editProfile()" mat-menu-item *ngxPermissionsOnly="['EDIT_PROFILE']">
                <mat-icon>edit</mat-icon>
                {{'EDIT_PROFILE' | translate }}
            </button>
        </mat-menu>
        <!-- <button class="mobile-hidden" appToggleFullscreen mat-icon-button [fxHide.xs]="true">
            <mat-icon>fullscreen</mat-icon>
        </button> -->
    </mat-toolbar>
    <mat-sidenav-container class="mo-app-inner">

        <!-- <mat-sidenav #sidemenu class="m-sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()" (opened)="sidePanelOpened = true" (closed)="sidePanelOpened = false">
            <nav-list></nav-list>
        </mat-sidenav> -->

        <!-- <div [@routerAnimations]="getState(o)" class="m-layout-body-container">-->
        <mat-sidenav-content (scroll)="handleScroll($event)">
            <div class="mo-layout-body-container" >
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>
    

    <!-- Show the toolbar only for mobile devices excluding search results page as we need to override active menu and introduce new funcationality that search filter would come when you click on search icon. 
    So we are introducing new toolbar inside search results page with new funcationlity added-->
    <!-- <mat-toolbar class="mo-main-header  mat-elevation-z6 mo-bottom" *ngIf="screenInfo.screenSize === 'xs' && !hideFooterMenuForMobile"> -->
    <mat-toolbar class="mo-main-header  mat-elevation-z6 mo-bottom" *ngIf="screenInfo.screenSize === 'xs'">


        <div class="mo-lo-section-container">

            <div class="mo-lo-widget">
                <a [routerLink]="['/user/home']">
                    <mat-icon>dashboard</mat-icon>
                    <div>{{'USER.MY_DASHBOARD' | translate }}</div>
                </a>
            </div>

            <div class="mo-lo-widget">
                <a [routerLink]="['/user/search']"  routerLinkActive="mo-active-menu">
                    <mat-icon>person_search</mat-icon>
                    <div>{{'USER.SEARCH' | translate }}</div>
                </a>
            </div>

            <div class="mo-lo-widget" [mat-menu-trigger-for]="userMenuXs" mat-icon-button class="ml-xs">
                <mat-icon>person</mat-icon>
            </div>
        </div>


        <mat-menu #userMenuXs="matMenu" x-position="before">

            <a mat-menu-item [routerLink]="['/new-user/logout']">
                <mat-icon>exit_to_app</mat-icon>
                {{'SIGN_OUT' | translate }}
            </a>

            <button (click)="changePassword()" mat-menu-item *ngxPermissionsOnly="['CHANGE_PASSWORD']">
                <mat-icon>lock_open</mat-icon>
                {{'CHANGE_PASSWORD' | translate }}
            </button>
            <button (click)="editProfile()" mat-menu-item *ngxPermissionsOnly="['EDIT_PROFILE']">
                <mat-icon>edit</mat-icon>
                {{'EDIT_PROFILE' | translate }}
            </button>
        </mat-menu>

    </mat-toolbar>



</div>
<!-- <div class="my-container">
    <ngx-loading [show]="loading"></ngx-loading>
</div> -->

<!--  
<div class="app mat-typography">
    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo">
            <h1>me<span>t</span><span class="mo-mirror">t</span>ioli</h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>

    
    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo  mo-logo3">
            <h1>me<span>t</span><span class="mo-mirror">t</span>ioli</h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>

    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo1">
            <h1>me<span>t</span><span class="mo-mirror">t</span>ioli</h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>





    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo  mo-logo2">
            <h1>me<span>t</span><span class="mo-mirror">t</span>ioli</h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>





    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo  mo-logo4">
            <h1>me<span>t</span><span class="mo-mirror">t</span>ioli</h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>







    
    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo11">
            <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>
    
    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo12">
            <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>



    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo13">
            <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>

    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo14">
            <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar>


    <mat-toolbar class="mat-elevation-z6">
        <div class="mo-logo mo-logo15">
            <h1>me<span class="mo-first">t</span><span class="mo-mirror">t</span><span class="mo-end-text">ioli</span></h1>
            
        </div>
        <div fxFlex>
        </div>
        
        <div class="login-name">
        <a [routerLink]="['/new-user/login']" >Login</a>
        </div>
        

    </mat-toolbar> 


   


</div>  -->