import { APP_BASE_HREF, DatePipe, KeyValuePipe, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeGB from '@angular/common/locales/en-GB';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginOtpComponent } from './components/login-otp/login-otp.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { CoreModule } from './core/core.module';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { AuthGuard } from './shared/guard/auth.guard';
import { AuthService } from './shared/services/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { ScrollHandleService } from './shared/services/scroll-handle.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// import { SignInComponent } from './feature/sign-in/sign-in.component';
// import { SignOutComponent } from './feature/sign-out/sign-out.component';
// import { SignUpComponent } from './feature/sign-up/sign-up.component';
// import { ForgotPasswordComponent } from './feature/forgot-password/forgot-password.component';
// import { VerifyEmailComponent } from './feature/verify-email/verify-email.component';


// import { IndexComponent } from './feature/index/index.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeGB);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    FaqsComponent,
    TermsConditionsComponent,
    LoginOtpComponent,
    LogoutComponent,
    ContactUsComponent,
    
    // IndexComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot('G-L4JBSFLQLN'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [AuthService, UserService, NotificationService,  ScrollHandleService, DatePipe, KeyValuePipe, AuthGuard,  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }, {provide: APP_BASE_HREF, useValue: environment.baseHref }],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
