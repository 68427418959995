import { Component, AfterViewInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-standard-page-template',
    templateUrl: './standard-page.component.html',
    styleUrls: ['./standard-page.scss']
})
export class StandardPageComponent implements AfterViewInit {

    @Input() titleIcon: string = '';
    @Input() title: string = '';
    @Input() subHeading: string = '';
    @Input() showBackLink = false;
    @Input() showHomeLink = false;
    @Input() showRouterUrl: string = '';

    @ViewChild('customHeader', {static: false}) customHeader!: any;
    @ViewChild('actionMenuItems', {static: false}) actions!: any;

    constructor(private cdRef: ChangeDetectorRef, private location: Location, private router: Router) { }

    shrinkHeader = false;
    showCustomHeader = false;
    showActionMenu = false;

    ngAfterViewInit() {
        this.showCustomHeader = this.customHeader.nativeElement && this.customHeader.nativeElement.children.length > 0;
        this.showActionMenu = this.actions.nativeElement && this.actions.nativeElement.children.length > 0;
        this.cdRef.detectChanges();
    }

    back() {
        if (this.showRouterUrl !== undefined && this.showRouterUrl !== ''){
            this.router.navigate([this.showRouterUrl]);
        }else{
            this.location.back();
        }
    }

    onScroll(event: Event) {
        this.shrinkHeader = !this.showCustomHeader && ((event.target as HTMLElement).scrollTop > 50);
    }
}
