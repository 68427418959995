
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IResultsUserDetails } from 'src/app/user-details-form/user-details';
import { UserService } from './user.service';

/* I think we cant maintain the values in service as if user refreshes the page,all the values will go off. So it is better to read the values from localstorage everytime */
export interface User {
  displayName?: string;
  isBackendUser?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public user: User = {};
  private debug = true;
  private userLoginStuats: boolean = false;
  //private email;
  //private password;
  private userHomePageUrl = '/user/home';
  // COLLECTIONS = {
  //   USERS: 'users',
  //   CONTACT: 'contacts'
  // };
  //  aysnc;
  users!: User;
  userProfileDetails!: IResultsUserDetails;
  constructor(
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private userService: UserService,

  ) {

  }

  // Returns true when user is looged in and email is verified

  isUserLoggedIn(): boolean {
    //return this.userLoginStuats;
    
    return (this.userService.getLocalStorageValue('beu') !== null || this.userService.getLocalStorageValue('uid') !== null);
  }

  setUserLoginStatus(value: boolean) {
    this.userLoginStuats = value;
  }

  setAuthToken(token: string) {
    localStorage.setItem('token', token);

  }

  getAuthToken() {
    return localStorage.getItem('token');
  }

  setUserDetails(user: any) {

    // The name comes from user table.  
    localStorage.setItem('displayName', user.name);


    if (user.is_backend_user) {
      this.user.isBackendUser = true;
      localStorage.setItem('beu', '1');
      this.router.navigate(['/mmo']);
    } else {
      localStorage.setItem('uid', user.user_id);
      //localStorage.setItem('pc', '0'); // Is Profile completed
      // Get Users Basic details and store in local storage
      this.userService.getUserDetailsById(null).subscribe(result => {
        this.userProfileDetails = result.data;
        let isProfileComplete = true;

        if (this.userProfileDetails.dob) {
          this.userService.setLocalStorageValue('dob', this.userProfileDetails.dob);
        }
        else {
          isProfileComplete = false;
        }
        if (this.userProfileDetails.gender_id) {
          this.userService.setLocalStorageValue('genderId', this.userProfileDetails.gender_id.toString());
        }
        else {
          isProfileComplete = false;
        }
        if (this.userProfileDetails.religion_id) {
          this.userService.setLocalStorageValue('religionId', this.userProfileDetails.religion_id.toString());
        }
        else {
          isProfileComplete = false;
        }
        if (this.userProfileDetails.qualification_id) {
          this.userService.setLocalStorageValue('qualificationId', this.userProfileDetails.qualification_id.toString());
        }
        else {
          isProfileComplete = false;
        }
        if(isProfileComplete){
          this.router.navigate(['user/home']);
        }else{
          this.router.navigate(['user/details/update']);
        }
        
      });


    }

  }
  // setItemInLocalstoage(name:string, value:string){
  //   localStorage.setItem(name, value);

  // }

  clearUserDetails() {
    localStorage.clear();
  }

  // removeUserDetails(){
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('uid');
  //   localStorage.removeItem('displayName');

  // }


  getUserDetails() {
    return {};
  }

  isDebugMode() {
    return this.debug;
  }

  displayConsoleMessage(message: any) {
    if (this.isDebugMode()) {

      if (typeof message === 'object') {


      }
      else {
        //console.log(message);
      }
    }
  }

  setError(errorMsg: string | []) {
    //console.log(errorMsg);
  }

  setSuccess(successMsg: string | []) {
    //console.log(successMsg);
  }

}

