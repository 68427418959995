import { Component, OnInit } from '@angular/core';



import { routerTransition } from '../router.animations';
const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
    selector: 'app-layout-new-user',
    animations: [routerTransition],
    templateUrl: './layout-new-user.component.html',
    styleUrls: ['./layout-new-user.component.scss']
})
export class LayoutNewUserComponent implements OnInit {

    constructor( 
    ) { 

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }

}
