<div class="m-form-container"  fxFlex.xs="95" fxFlex.sm="70" fxFlex.md="40" fxFlex.lg="30" fxFlex.xl="25">
  <mat-card class="mo-sr-card">
    <mat-card-header>

      <!-- <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> -->
      <mat-card-title>{{'LOGIN' | translate }} </mat-card-title>

    </mat-card-header>
    <form [formGroup]="formLoginGroup" autocomplete="off">

      <div>
        <mat-form-field>
          <mat-label>{{ 'USER.EMAIL' | translate }}</mat-label>
          <input type="text" matInput formControlName="email" placeholder="">
        </mat-form-field>

      </div>
      <div>
        <mat-form-field>
          <mat-label>{{ 'USER.PASSWORD' | translate }}</mat-label>
          <input type="password" matInput formControlName="password" placeholder="">
        </mat-form-field>
      </div>

      <div>
        <button mat-button mat-raised-button color="primary" type="submit" (click)="saveLogin()"
          [disabled]="!formLoginGroup.valid">{{
          'LOGIN' | translate }}
          <app-spinner *ngIf="showSpinner"></app-spinner>
        </button>
      </div>
    </form>

    <div class="mo-ln-links">
      <a class="mo-ln-otp" [routerLink]="'/new-user/login/otp'">Login with OTP</a>
      <a class="mo-ln-fp" [routerLink]="'/new-user/login/otp'">Forgot Password?</a>
      <a class="mo-ln-sp" [routerLink]="'/new-user/signup'">New User? Signup</a>
    </div>

  </mat-card>
</div>