<div class="mo-landing-page-container" [ngClass]="screenInfo.cssClass">
    <div class="mo-landing-page-banner">

        <section class="mo-lp-top-section">

            <!-- <div class="mo-logo-i">
                    <img src="../../../assets/images/logo-sm.png" width="150" alt="Vaniya Chettiyar"/>
                </div> -->
            <div class="mo-logo">
                <div class="mo-logo-dots"><span>.</span><span>.</span></div>
                <div>M<span>ettioli</span>

                    <!-- <span>.in</span> -->
                </div>
            </div>

            <!-- <div class="mo-logo">
            <div class="mo-logo-dots"><span>.</span><span>.</span></div>
            <div>M<span>etti</span>
            <span class="mo-logo-o">O</span>
            <span class="mo-logo-li">li</span>
                
        </div>
        </div>

        <div class="mo-logo">
            <div class="mo-logo-dots"><span>.</span><span>.</span></div>
            <div>M<span>etti</span>
            <span class="mo-logo-o2">O</span>
            <span class="mo-logo-li">li</span>
                
            </div>
        </div>

        <div class="mo-logo">
            <div class="mo-logo-dots"><span>.</span><span>.</span></div>
            <div>M<span>etti</span>
            <span class="mo-logo-o2">O</span>
            <span class="mo-logo-li">li</span>
                
            </div>
        </div> -->


            <div class="mo-lp-top-section-content">
                <!-- <div class="mo-lp-header">
                <h1>Why Vaniya Chettiyar?</h1>
            </div>
            <div class="mo-lp-header-content">
                
            </div>
            <ul>
                <li>Safe and secure</li>
                <li>Easy to use</li>
                <li>Comprehensive profiles</li>
                <li>Offer a range of features to help you connect</li>
                <li>Dedicated customer service</li>
                <li>And the most importantly, it is <span> 100% free.</span></li>
                <li>No Payment is required for getting contact number</li>


            </ul> -->

                <div class="mo-lp-header">
                    <!-- <h1>"We believe in creating a family, not just a marriage"</h1> -->
                    <!-- <h1>Extensive database of verified profiles</h1><h1> Advanced features to help your partner search ease.</h1>
                <h1>100% free.. No payment required for contact numbers.. No hidden fees or charges.</h1> -->

                    <!-- <h2>Verified profiles for a secure and trustworthy experience,  Advanced features and More</h2> -->
                    <div class="mo-lp-hd-first">
                        <h2>Extensive Verified Profiles,</h2>
                        <h2>&nbsp;Advanced Features and More..</h2>
                    </div>
                    <div class="mo-lp-hd-second">
                        <h3><span>100% Absolutely free..</span></h3>
                        <h4>&nbsp; No payment is required for contact numbers</h4>
                    </div>

                    <div class="mo-lp-hd-third">
                        <h4>Looks Amazing? Join Us Today.</h4>
                    </div>

                    <form [formGroup]="formUserLandingGroup" autocomplete="off">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'Enter Your Mobile Number' | translate }}</mat-label>
                            <input type="number" matInput formControlName="mobileNo" placeholder="">
                        </mat-form-field>

                        <div class="mo-lp-button-container">
                            <a [routerLink]="['/new-user/signup']">
                                <button [disabled]="!formUserLandingGroup.valid" mat-button mat-raised-button color="primary" type="submit">{{
                                    'Join' | translate }}

                                </button>
                            </a>
                        </div>

                    </form>
                    <div class="mo-form-field-error"
                        *ngIf="(formUserLandingGroup.controls['mobileNo'].invalid && (formUserLandingGroup.controls['mobileNo'].touched)) && formUserLandingGroup.controls['mobileNo'].errors?.['pattern']">
                        Mobile number should be number and lenght should be 10.
                    </div>
                    <div  class="mo-lp-login"> Already a memeber? <a [routerLink]="['/new-user/login']">Login</a></div>
                </div>

            </div>



        </section>


    </div>
    <div class="mo-lp-features">
        <section class="mo-lp-feature">
            <div class="mo-lp-fea-text-container">
                <div class="mo-lp-fea-header">Large Database</div>
                <div class="mo-lp-fea-text">
                    We have collected data for each communites from many sources and verified each and every profile and
                    put
                    it together for you.
                </div>

            </div>
            <div class="mo-lp-fea-image">
                <img src="../../../assets/images/db11-c.jpg" alt="Large Database" />
            </div>

        </section>

        <section class="mo-lp-feature mo-reverse">
            <div class="mo-lp-fea-text-container">
                <div class="mo-lp-fea-header">Share Your Profile With Everyone</div>
                <div class="mo-lp-fea-text">
                    We don't stop you to search your partner from other sources. But we encourage you to manage your
                    profile
                    at one place and share your profile link with anyone via whatsapp, Telegram etc., So your profile
                    will
                    always be upto date.
                </div>

            </div>
            <div class="mo-lp-fea-image">
                <img src="../../../assets/images/whatsapp4.jpg" alt="Share your profile to anyone" />

            </div>

        </section>

        <section class="mo-lp-feature">
            <div class="mo-lp-fea-text-container">
                <div class="mo-lp-fea-header">Everything is Free</div>
                <div class="mo-lp-fea-text">
                    We offer all the services including contact numbers at free of cost. No hidden charges.
                    <div>You don't need to register and wait for someone to call you. You can reach anyone you like.
                    </div>
                </div>

            </div>
            <div class="mo-lp-fea-image">
                <img src="../../../assets/images/contact3.jpg" alt="Large Database" />
            </div>

        </section>


        <section class="mo-lp-feature mo-reverse">
            <div class="mo-lp-fea-text-container">
                <div class="mo-lp-fea-header">Safe and Secure</div>
                <div class="mo-lp-fea-text">
                    We always respect your safety concern.
                    Our system keep monitor for any fradulant users and suspend them immediately.
                    We have put our best validation machanism to restrict fake users to access your profiles.
                </div>

            </div>
            <div class="mo-lp-fea-image">
                <img src="../../../assets/images/safe4.jpg" alt="Safe and Secure" />
            </div>

        </section>
        <section class="mo-lp-footer">
            <ul>
                <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
                <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
                <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
                <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li>
                <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
            </ul>
            <div class="mo-lp-cr">&copy; 2023 Vaniya Chettiyar Matrimony, All rights reserved.</div>
        </section>


    </div>
</div>