<div class="mo-form-container" [ngClass]="screenInfo.cssClass">
    <mat-card class="mo-sr-card">
        <mat-card-header>

            <!-- <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> -->
            <mat-card-title>{{'Primary Details' | translate }} </mat-card-title>

        </mat-card-header>

        <form [formGroup]="formPrimaryDetailsGroup" autocomplete="off">
            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.FIRST_NAME' | translate }}</mat-label>
                    <input type="text" matInput formControlName="firstName" placeholder="">

                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['firstName'].invalid && (formPrimaryDetailsGroup.controls['firstName'].touched)) && formPrimaryDetailsGroup.controls['firstName'].errors?.['required']">
                    First Name is required.
                </div>
                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['firstName'].invalid && ( formPrimaryDetailsGroup.controls['firstName'].touched)) &&formPrimaryDetailsGroup.controls['firstName'].errors?.['minlength']">
                    First Name length should be minimum of 4 characters.
                </div>

            </div>

            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.LAST_NAME' | translate }}</mat-label>
                    <input type="text" matInput formControlName="lastName" placeholder="">
                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['lastName'].invalid && ( formPrimaryDetailsGroup.controls['lastName'].touched)) && formPrimaryDetailsGroup.controls['lastName'].errors?.['required']">
                    Last Name is required.
                </div>
            </div>


            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.EMAIL' | translate }}</mat-label>
                    <input type="text" matInput formControlName="email" placeholder="">
                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['email'].invalid && ( formPrimaryDetailsGroup.controls['email'].touched)) &&formPrimaryDetailsGroup.controls['email'].errors?.['required']">
                    Email is required.
                </div>
                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['email'].invalid && ( formPrimaryDetailsGroup.controls['email'].touched)) &&formPrimaryDetailsGroup.controls['email'].errors?.['email']">
                    Email format is wrong.
                </div>
            </div>

            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.MOBILE_NUMBER' | translate }}</mat-label>
                    <input type="text" matInput formControlName="mobileNo" placeholder="">
                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['mobileNo'].invalid && ( formPrimaryDetailsGroup.controls['mobileNo'].touched)) &&formPrimaryDetailsGroup.controls['mobileNo'].errors?.['required']">
                    Mobile number is required.
                </div>
                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['mobileNo'].invalid && ( formPrimaryDetailsGroup.controls['mobileNo'].touched)) &&formPrimaryDetailsGroup.controls['mobileNo'].errors?.['pattern']">
                    Mobile number should be numbers and length should be 10 characters.
                </div>
            </div>


            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.PASSWORD' | translate }}</mat-label>
                    <input type="password" matInput formControlName="password" placeholder="">
                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['password'].invalid && ( formPrimaryDetailsGroup.controls['password'].touched)) &&formPrimaryDetailsGroup.controls['password'].errors?.['required']">
                    Password is required
                </div>
                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['password'].invalid && ( formPrimaryDetailsGroup.controls['password'].touched)) &&formPrimaryDetailsGroup.controls['password'].errors?.['minlength']">
                    Password length should be minimum of 8 characters.
                </div>

                <div class="mo-form-field-error" *ngIf="formPrimaryDetailsGroup.controls['password'].errors?.['passwordStrength']">
                    Your password must have atleast one lower case, one upper case and one numeric characters.
                </div>
            </div>

            <div class="mo-form-field-container">
                <mat-form-field>
                    <mat-label>{{ 'USER.RETYPE_PASSWORD' | translate }}</mat-label>
                    <input type="password" matInput formControlName="confirmPassword" placeholder="">
                </mat-form-field>

                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['confirmPassword'].invalid && ( formPrimaryDetailsGroup.controls['confirmPassword'].touched)) &&formPrimaryDetailsGroup.controls['confirmPassword'].errors?.['required']">
                    Confirm Password is required
                </div>
                <div class="mo-form-field-error"
                    *ngIf="(formPrimaryDetailsGroup.controls['confirmPassword'].invalid && ( formPrimaryDetailsGroup.controls['confirmPassword'].touched)) &&formPrimaryDetailsGroup.controls['confirmPassword'].errors?.['mismatch']">
                    Password and Confirm Password should be same.
                </div>

                

            </div>
            <div>
                <button mat-button mat-raised-button color="primary" type="submit" (click)="saveRegistration()"
                    [disabled]="!formPrimaryDetailsGroup.valid">{{
                    'USER.REGISTER' | translate }}
                    <app-spinner *ngIf="showSpinner"></app-spinner>
                </button>
            </div>
        </form>
    </mat-card>
</div>