import { Pipe, PipeTransform } from '@angular/core';
import { FamilyStatus } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'familyStatus'  
})
export class FamilyStatusPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private familyStatus: FamilyStatus[] = [{}];


  transform(familyStatusId: number | undefined): string {

    if(!familyStatusId){
      return "";
    }
      /* Check if the familyStatus array has empty object */
    if (this.familyStatus.length == 1) {
      this.masterDataService.familyStatus$.subscribe(familyStatus => {
        this.familyStatus = familyStatus;
      })
    }
    const familyStatus = this.familyStatus.filter(familyStatus => familyStatus.family_status_id == familyStatusId )
    return familyStatus[0].family_status || '';
  }

}
