<div class="mo-contact-container">
    <section>
        <h2> Contact Us</h2>
        <div>For any kind of assistance, please feel free to contact us support@mettioli.in</div>
        <div> or call/whatsapp to 8248678175</div>
    </section>

    <!-- <section>
        <h2>Our Office Address:</h2>
        <div>Vaniya Chettiyar Matrimony,</div>
        <div>126, Courtallam Road, Melagaram,</div>
        <div>Tenkasi - 627818.</div>
        <div>Tamilnadu.</div>
        <div>Email: support@mettioli.in</div>

    </section> -->
</div>