import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
import { Relation } from '../master-data';

@Pipe({
  name: 'relation'  
})
export class RelationPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private relations: Relation[] = [{}];


  transform(relation_id: number | undefined): string {

    if(!relation_id){
      return "";
    }
      /* Check if the relation array has empty object */
    if (this.relations.length == 1) {
      this.masterDataService.relation$.subscribe(relations => {
        this.relations = relations;
      })
    }
    const relation = this.relations.filter(relation => relation.relation_id == relation_id )
    return relation[0].relation || '';
  }

}
