import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
    Title: string;
    SubTitle: string;
    ErrorContent: string;
    constructor() { this.Title = "403"; this.SubTitle = "Forbidden/Unauthorized !"; this.ErrorContent = "You are not authorized to access Submission Portal application. Please Contact system administrator to gain access."; }

  ngOnInit() {
  }

}
