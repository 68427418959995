import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Habit, ICity, ICountry, IState } from './master-data';

@Injectable({ providedIn: 'root' })
export class MasterDataService {

  private casteSource = new BehaviorSubject<any>({});
  caste$ = this.casteSource.asObservable();


  private bloodGroupSource = new BehaviorSubject<any>({});
  bloodGroup$ = this.bloodGroupSource.asObservable();


  private bodyTypeSource = new BehaviorSubject<any>({});
  bodyType$ = this.bodyTypeSource.asObservable();

  private citySource = new BehaviorSubject<ICity[]>([]);
  city$ = this.citySource.asObservable();

  private countrySource = new BehaviorSubject<ICountry[]>([]);
  country$ = this.countrySource.asObservable();


  private genderSource = new BehaviorSubject<any>({});
  gender$ = this.genderSource.asObservable();


  private gothramSource = new BehaviorSubject<any>({});
  gothram$ = this.gothramSource.asObservable();


  private grahamSource = new BehaviorSubject<any>({});
  graham$ = this.grahamSource.asObservable();


  private habitSource = new BehaviorSubject<Habit[]>([]);
  habit$ = this.habitSource.asObservable();

  private maritalStatusSource = new BehaviorSubject<any>({});
  maritalStatus$ = this.maritalStatusSource.asObservable();

  private motherTongueSource = new BehaviorSubject<any>({});
  motherTongue$ = this.motherTongueSource.asObservable();

  private occupationSource = new BehaviorSubject<any>({});
  occupation$ = this.occupationSource.asObservable();

  private occupationCatSource = new BehaviorSubject<any>({});
  occupationCategory$ = this.occupationCatSource.asObservable();

  private physicalStatusSource = new BehaviorSubject<any>({});
  physicalStatus$ = this.physicalStatusSource.asObservable();

  private familyStatusSource = new BehaviorSubject<any>({});
  familyStatus$ = this.familyStatusSource.asObservable();

  private qualificationSource = new BehaviorSubject<any>({});
  qualification$ = this.qualificationSource.asObservable();

  private relationSource = new BehaviorSubject<any>({});
  relation$ = this.relationSource.asObservable();

  private raasiSource = new BehaviorSubject<any>({});
  raasi$ = this.raasiSource.asObservable();


  private religionSource = new BehaviorSubject<any>({});
  religion$ = this.religionSource.asObservable();

  private residentStatusSource = new BehaviorSubject<any>({});
  residentStatus$ = this.residentStatusSource.asObservable();

  private starSource = new BehaviorSubject<any>({});
  star$ = this.starSource.asObservable();


  private stateSource = new BehaviorSubject<IState[]>([]);
  state$ = this.stateSource.asObservable();

  private suspectReasonSource = new BehaviorSubject<any>({});
  suspectReason$ = this.suspectReasonSource.asObservable();


  //private getAlerterviceUrl = environment.apiUrl + 'Alert/GetAllAlerts';
  private casteJsonPath = '/assets/master-data/castes.json';
  private motherTongueJsonPath = '/assets/master-data/mother-tongues.json';
  private bodyTypesJsonPath = '/assets/master-data/body-types.json';
  private bloodGroupsJsonPath = '/assets/master-data/blood-groups.json';
  private citiesJsonPath = '/assets/master-data/cities.json';
  private countriesJsonPath = '/assets/master-data/countries.json';
  private gendersJsonPath = '/assets/master-data/genders.json';
  private gothramJsonPath = '/assets/master-data/gothrams.json';
  private grahamJsonPath = '/assets/master-data/grahams.json';
  private habitsJsonPath = '/assets/master-data/habits.json';
  private maritalStatuesJsonPath = '/assets/master-data/marital-statuses.json';
  private occupationsJsonPath = '/assets/master-data/occupations.json';
  private occupationsCatJsonPath = '/assets/master-data/occupation-categories.json';
  private physicalStatusesJsonPath = '/assets/master-data/physical-statuses.json';
  private familyStatusesJsonPath = '/assets/master-data/family-statuses.json';
  private qualificationsJsonPath = '/assets/master-data/qualifications.json';
  private raasisJsonPath = '/assets/master-data/raasis.json';
  private relationsJsonPath = '/assets/master-data/relations.json';
  private religionsJsonPath = '/assets/master-data/religions.json';
  private residentStatusesJsonPath = '/assets/master-data/resident-statuses.json';
  private starsJsonPath = '/assets/master-data/stars.json';
  private statesJsonPath = '/assets/master-data/states.json';
  private suspectReasonsJsonPath = '/assets/master-data/suspect-reasons.json';


  constructor( private readonly http: HttpClient ) {  }


  loadCastes() {
    this.http.get<any>(this.casteJsonPath).subscribe(data => {
      this.casteSource.next(data.castes);
    })
  }

  loadBodyTypes() {
    this.http.get<any>(this.bodyTypesJsonPath, {responseType: 'json'}).subscribe(data => {
      this.bodyTypeSource.next(data.bodyTypes);
    })
  }

  loadBloodGroups() {
    this.http.get<any>(this.bloodGroupsJsonPath, {responseType: 'json'}).subscribe(data => {
      this.bloodGroupSource.next(data.bloodGroups);
    })
  }

  loadCities() {
    this.http.get<any>(this.citiesJsonPath).subscribe(data => {
      this.citySource.next(data.cities);
    })
  }


  loadCountries() {
    this.http.get<any>(this.countriesJsonPath).subscribe(data => {
      this.countrySource.next(data.countries);
    })
  }


  loadGenders() {
    this.http.get<any>(this.gendersJsonPath, {responseType: 'json'}).subscribe(data => {
      this.genderSource.next(data.genders);
    })
  }


  loadGothrams() {
    this.http.get<any>(this.gothramJsonPath).subscribe(data => {
      this.gothramSource.next(data.gothrams);
    })
  }


  loadGrahams() {
    this.http.get<any>(this.grahamJsonPath).subscribe(data => {
      this.grahamSource.next(data.grahams);
    })
  }

  loadHabits() {
    this.http.get<any>(this.habitsJsonPath).subscribe(data => {
      this.habitSource.next(data.habits);
    })
  }


  loadMaritalStatuses() {
    this.http.get<any>(this.maritalStatuesJsonPath).subscribe(data => {
      this.maritalStatusSource.next(data.maritalStatuses);
    })
  }


  loadMotherTongues() {
    this.http.get<any>(this.motherTongueJsonPath).subscribe(data => {
      this.motherTongueSource.next(data.motherTongues);
    })
  }


  loadOccupations() {
    this.http.get<any>(this.occupationsJsonPath).subscribe(data => {
      this.occupationSource.next(data.occupations);
    })
  }

  loadOccupationsCategories() {
    this.http.get<any>(this.occupationsCatJsonPath).subscribe(data => {
      this.occupationCatSource.next(data.occupations);
    })
  }


  loadPhysicalStatuses() {
    this.http.get<any>(this.physicalStatusesJsonPath).subscribe(data => {
      this.physicalStatusSource.next(data.physicalStatuses);
    })
  }


  loadFamilyStatuses() {
    this.http.get<any>(this.familyStatusesJsonPath).subscribe(data => {
      this.familyStatusSource.next(data.familyStatuses);
    })
  }


  loadQualifications() {
    this.http.get<any>(this.qualificationsJsonPath).subscribe(data => {
      this.qualificationSource.next(data.qualifications);
    })
  }


  loadRaasis() {
    this.http.get<any>(this.raasisJsonPath).subscribe(data => {
      this.raasiSource.next(data.raasis);
    })
  }


  loadRelations() {
    this.http.get<any>(this.relationsJsonPath).subscribe(data => {
      this.relationSource.next(data.relations);
    })
  }


  loadReligions() {
    this.http.get<any>(this.religionsJsonPath).subscribe(data => {
      this.religionSource.next(data.religions);
    })
  }

  loadResidingStauses() {
    this.http.get<any>(this.residentStatusesJsonPath).subscribe(data => {
      this.residentStatusSource.next(data.residentStatus);
    })
  }


  loadStars() {
    this.http.get<any>(this.starsJsonPath).subscribe(data => {
      this.starSource.next(data.stars);
    })
  }


  loadStates() {
    this.http.get<any>(this.statesJsonPath).subscribe(data => {
      this.stateSource.next(data.states);
    })
  }


  loadSuspectReasons() {
    this.http.get<any>(this.suspectReasonsJsonPath).subscribe(data => {
      this.suspectReasonSource.next(data.suspectReasons);
    })
  }


  loadMasterData() {

    this.loadBodyTypes();
    this.loadBloodGroups();
    this.loadCastes();
    this.loadCities();
    this.loadCountries();
    this.loadGenders();
    //this.loadGothrams();
    this.loadGrahams();
    this.loadHabits();
    this.loadMaritalStatuses();
    this.loadMotherTongues();
    this.loadOccupations();
    this.loadOccupationsCategories();
    this.loadPhysicalStatuses();
    this.loadFamilyStatuses();
    this.loadQualifications();
    this.loadRaasis();
    this.loadRelations();
    this.loadReligions();
    this.loadResidingStauses();
    this.loadStars();
    this.loadStates();
    //this.loadSuspectReasons();
  }


}
