import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IScreensize } from 'src/app/app';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public screenInfo!: IScreensize;
  formUserLandingGroup!: FormGroup;
  constructor(private screensizeDetectService: ScreensizeDetectService,private readonly fb: FormBuilder) { 
    
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
  });
  }

  ngOnInit(): void {
    this.setFormGroup();

  }

  setFormGroup() {
    this.formUserLandingGroup = this.fb.group({
      mobileNo: ['', [Validators.pattern('[1-9][0-9]{9}')]]
    });
  }

  getResult(){


    
  }
}
