import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../master-data.service';
import { Graham } from '../master-data';

@Pipe({
  name: 'graham'  
})
export class GrahamPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private grahams: Graham[] = [{}];


  transform(graham_id: string | number | undefined): string {

    if(!graham_id){
      return "";
    }
      /* Check if the graham array has empty object */
    if (this.grahams.length == 1) {
      this.masterDataService.graham$.subscribe(grahams => {
        this.grahams = grahams;
      })
    }
    const graham = this.grahams.filter(graham => graham.graham_id == +graham_id )
    return graham[0].graham || '';
  }

}
