import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IScreensize } from 'src/app/app';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ScreensizeDetectService } from 'src/app/shared/services/screensize-detect.service';
import { UserService } from 'src/app/shared/services/user.service';
import { IResultsUserDetails } from '../user-details';


export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);

    const hasLowerCase = /[a-z]+/.test(value);

    const hasNumeric = /[0-9]+/.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;

    //Enable this if we want to highlight which is missing
    // return !passwordValid ? {
    //   passwordStrength: {
    //     hasUpperCase: true,
    //     hasLowerCase: true,
    //     hasNumeric: false
    //   }
    // } : null;
    return !passwordValid ? { passwordStrength: true } : null;
  }
}






@Component({
  selector: 'app-primary-details',
  templateUrl: './primary-details.component.html',
  styleUrls: ['./primary-details.component.scss']
})
export class PrimaryDetailsComponent implements OnInit {

  public screenInfo!: IScreensize;
  userProfileDetails!: IResultsUserDetails;
  displayForm: boolean = false;
  formPrimaryDetailsGroup!: FormGroup;
  userId: string | null = null;
  public showSpinner: boolean = false;
  constructor(
    private readonly fb: FormBuilder,
    public authService: AuthService,
    public dialog: MatDialog,
    private userService: UserService,
    private readonly translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private screensizeDetectService: ScreensizeDetectService
  ) {
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
  }

  ngOnInit(): void {

    // Check if there is a id in the url 
    //this.userId = this.route.snapshot.paramMap.get('userId');
    //this.userId = (userId) ? userId : ''; // Apply empty string if null

    this.setFormGroup();

    // if (this.userId) {
    //   this.userService.getUserDetailsById(this.userId).subscribe(data => {
    //     this.userProfileDetails = data.user;
    //     this.displayForm = true;
    //     //this.getUserHoroscope(this.user.user_id);
    //   })
    // }
  }

  ngAfterViewInit() {
    // child is set
    //this.displayForm = true;
  }

  setFormGroup() {

    this.formPrimaryDetailsGroup = this.fb.group({

      firstName: [null, [Validators.required, Validators.minLength(4)]],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      countryId: ['91', [Validators.required, Validators.pattern('((1|44)-)?[1-9]{1,4}')]],
      mobileNo: [null, [Validators.required, Validators.pattern('[1-9][0-9]{9}')]],
      password: [null, [Validators.required, Validators.minLength(8), createPasswordStrengthValidator()]],
      confirmPassword: [null, [Validators.required, this.MatchValidator()]],

    });
  }

  MatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const confirmPasswordValue = control.value;
      let passwordValue = null;

      if (this.formPrimaryDetailsGroup) {
        passwordValue = this.formPrimaryDetailsGroup.controls['password'].value;
      }

      return confirmPasswordValue !== passwordValue
        ? { mismatch: true }
        : null;
    };
  }

  saveRegistration() {
    this.showSpinner = true;
    const userRegisterDetailsJson = {
      first_name: this.formPrimaryDetailsGroup.get('firstName')!.value,
      last_name: this.formPrimaryDetailsGroup.get('lastName')!.value,
      email: this.formPrimaryDetailsGroup.get('email')!.value,
      mobile_no: this.formPrimaryDetailsGroup.get('mobileNo')!.value,
      country_id: this.formPrimaryDetailsGroup.get('countryId')!.value,
      password: this.formPrimaryDetailsGroup.get('password')!.value,
      password_confirmation: this.formPrimaryDetailsGroup.get('confirmPassword')!.value,

    };

    // Check if one of the fields in the form group has been modified
    if (this.formPrimaryDetailsGroup.dirty && this.formPrimaryDetailsGroup.valid) {

      // Insert User DETAILS 
      this.userService.registerUser(userRegisterDetailsJson).subscribe(response => {

        /* Hide the spinner */
        this.showSpinner = false;

        /* Get translation text */
        let successMsg = '';
        this.translate.get('PRIMARY_DETAILS_SAVE_SUCCESS_MSG').subscribe((tranlatedText: string) => {
          successMsg = tranlatedText;
        });


        /* Display the notification */
        this.notificationService.displayNotification(successMsg);
        this.router.navigate(['new-user/login/otp/verify/', response.data.verification_id]);

      },

        err => {
          this.showSpinner = false;
        });


    }
  }



}
