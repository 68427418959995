import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  // Where to display the spinner Button / Body. This helps to decide the size/color of the spinner 
  @Input() element: string = 'button';
  

  public spinnerDiameter: number = 20;
  public strokeWidth: number = 3;
  constructor() { }

  ngOnInit(): void {
  }

}
