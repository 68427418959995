import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
// import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
// import { APP_CONFIG, AppConfig } from '../app.config';
// import { ConfigService } from '../services/config.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StandardPageComponent } from './page-templates/standard-page.component';
// import { KeyValueComponent } from './key-value/key-value.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MasterDataModule } from '../master-data/master-data.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { HeaderComponent } from './header/header/header.component';
import { NotificationComponent } from './notification/notification.component';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScreensizeDetectService } from './services/screensize-detect.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        NgxPermissionsModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FlexLayoutModule,
        MatSnackBarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatDialogModule,
        MatStepperModule,
        MatCheckboxModule,
        MatInputModule,
        MatSelectModule,
        // NgxLoadingModule.forRoot({
        //     animationType: ngxLoadingAnimationTypes.wanderingCubes,
        //     backdropBackgroundColour: 'rgba(0,0,0,0.1)',
        //     backdropBorderRadius: '4px',
        //     primaryColour: '#ffffff',
        //     secondaryColour: '#ffffff',
        //     tertiaryColour: '#ffffff'
        // }),
        // LoadingModule.forRoot({
        //     animationType: ANIMATION_TYPES.circle,
        //     backdropBackgroundColour: 'rgba(0,0,0,0.08)',
        //     backdropBorderRadius: '1px',
        //     primaryColour: '#FFF',
        //     secondaryColour: '#854195',
        //     tertiaryColour: '#FFF'
        // }),
        MatDatepickerModule,
        MatNativeDateModule,
        MomentDateModule,
        TranslateModule,
        MasterDataModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressBarModule,
        MatSliderModule,
        MatProgressSpinnerModule,
        DragDropModule,
        MatBottomSheetModule

    ],
    exports: [
        CommonModule,
        NgxPermissionsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule,
        FlexLayoutModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatChipsModule,
        StandardPageComponent,

        // NgxResizeWatcherDirective,
        // LoadingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MomentDateModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        // ConfirmDialogComponent
        HeaderComponent,
        MasterDataModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressBarModule,
        MatSliderModule,
        ScrollTrackerDirective,
        SpinnerComponent,
        FirstLetterPipe,
        SafeHtmlPipe,
        DragDropModule,
        MatBottomSheetModule


    ],
    declarations: [
        StandardPageComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        NotificationComponent,
        HeaderComponent,
        ScrollTrackerDirective,
        SpinnerComponent,
        FirstLetterPipe,
        SafeHtmlPipe

    ],

    providers: [
        ScreensizeDetectService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        //ScrollHandleService
        // CommonService,
        // ConfigService,
        // PermissionsService,
        // DataExchangeService,
        // { provide: APP_CONFIG, useValue: AppConfig }
    ]
})
export class SharedModule { }


