import { Pipe, PipeTransform } from '@angular/core';
import { PhysicalStatus } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'physicalStatus'
})
export class PhysicalStatusPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private physicalStatus: PhysicalStatus[] = [{}];


  transform(physicalStatusIds: number[] | number | undefined): string {

    /* Check if the physicalStatus array has empty object */
    if (this.physicalStatus.length == 1) {
      this.masterDataService.physicalStatus$.subscribe(physicalStatus => {
        this.physicalStatus = physicalStatus;
      })
    }

    if (!physicalStatusIds) {
      // Undefined
      return "";
    }
    else if (typeof (physicalStatusIds) === 'object') {
      let physicalStatusString: string = '';
      // Array 
      physicalStatusIds.forEach((motherTongue_id) => {
        const physicalStatus = this.physicalStatus.filter(physicalStatus => physicalStatus.physical_status_id === motherTongue_id);
        if (physicalStatusString === '') {
          // Append without comma
          physicalStatusString += physicalStatus[0].physical_status || ''
        } else {
          // Append with comma
          physicalStatusString += ', ' + physicalStatus[0].physical_status || ''
        }


      });
      return physicalStatusString;

    } else {

      // number
      const physicalStatus = this.physicalStatus.filter(physicalStatus => physicalStatus.physical_status_id == physicalStatusIds)
      return physicalStatus[0].physical_status || '';
    }
  }

}
