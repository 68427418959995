import { Pipe, PipeTransform } from '@angular/core';
import { Raasi } from '../master-data';
import { MasterDataService } from '../master-data.service';

@Pipe({
  name: 'raasi'  
})
export class RaasiPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private raasis: Raasi[] = [{}];


  transform(raasi_id: string | number | undefined): string {
    
    if(!raasi_id){
      return "";
    }
      /* Check if the raasi array has empty object */
    if (this.raasis.length == 1) {
      this.masterDataService.raasi$.subscribe(raasis => {
        this.raasis = raasis;
      })
    }
    const raasi = this.raasis.filter(raasi => raasi.raasi_id == +raasi_id )
    return raasi[0].raasi || '';
  }

}
